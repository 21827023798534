import styled from 'styled-components'
import { Width } from '@/components'
import { Breakpoints, Sizes, Type } from '@/styles'
import JobDepartment from '../JobDepartment/JobDepartment'

export const CareersComponent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: calc(${Sizes.navHeight} + 130px);
  margin-bottom: 97px;
  position: relative;
  z-index: 2;

  @media (min-width: ${Breakpoints.tablet}) {
    padding-top: calc(${Sizes.navHeight} + 197px);
    margin-bottom: 0;
  }
`

export const Title = styled.h1`
  ${Type.header3};
  margin-bottom: 27px;
  max-width: 714px;
`

export const Description = styled.div`
  ${Type.body20};
  max-width: 674px;
  margin-bottom: 150px;
`

export const SupportiveTitle = styled.h3`
  ${Type.header3};
  margin-bottom: 29px;
  max-width: 714px;
`

export const SupportiveWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 35px;
  @media (min-width: ${Breakpoints.tablet}) {
    grid-template-columns: 1fr 1fr 1fr;
    gap: 50px;
  }
`

export const SupportiveItem = styled.div`
  display: flex;
  flex-direction: column;
  min-with: 300px;
`

export const SupportiveSectionTitle = styled.div`
  ${Type.subhead20};
  margin-bottom: 19px;
`

export const SupportiveSectionDescription = styled.div`
  ${Type.body30};
`
export const JobListWrapper = styled(Width)`
  margin-top: 200px;
`

export const StyledJobDepartment = styled(JobDepartment)`
  margin-bottom: 75px;
`
