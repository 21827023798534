import React from 'react'
import styled from 'styled-components'
import Image from 'gatsby-image'

import { LocalizedLink, HoverArrow, Width } from '@/components'
import { Breakpoints, Sizes, Type, Colors } from '@/styles'

export const AboutComponent = styled.div``

export const Hero = styled.div`
  position: relative;
  margin-bottom: 300px;
  padding-top: calc(${Sizes.navHeight} + 80px);

  > div {
    padding-bottom: 70px;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    bottom: 0;
    right: 0;
    background: ${({ bg }) => (bg ? bg : Colors.gray)};
  }

  &:after {
    content: '';
    position: absolute;
    top: 56%;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 50%;
    transform: skewy(9.85deg);
    transform-origin: 50% 0;
    backface-visibility: hidden;
    background: ${({ bg }) => (bg ? bg : Colors.gray)};
    z-index: -1;
  }

  @media (min-width: ${Breakpoints.desktop}) {
    padding-top: calc(${Sizes.navHeight} + 130px);
    margin-bottom: 450px;

    &:after {
      top: 65%;
    }
  }
`

export const Title = styled.h1`
  ${Type.header2};
  color: ${({ color }) => (color ? color : Colors.text)};
  text-align: ${({ align }) => (align ? align : 'left')};
  margin-bottom: 35px;
  @media (min-width: ${Breakpoints.desktop}) {
    max-width: 848px;
  }
`

export const TitleH3 = styled.h2`
  ${Type.header3};
  color: ${({ color }) => (color ? color : Colors.text)};
  text-align: ${({ align }) => (align ? align : 'left')};
  margin-bottom: 35px;
  @media (min-width: ${Breakpoints.desktop}) {
    max-width: 848px;
  }
`

export const Text = styled.div`
  ${Type.body20};
  color: ${({ color }) => (color ? color : Colors.text)};
  text-align: ${({ align }) => (align ? align : 'left')};

  & > p {
    margin-bottom: 2rem;
  }
  & > p:last-child {
    margin-bottom: 0rem;
  }

  @media (min-width: ${Breakpoints.desktop}) {
    max-width: 848px;
  }
`

export const CountInfo = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  justify-items: center;
  gap: 2rem;
  margin-top: 3rem;

  @media (min-width: ${Breakpoints.desktop}) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  & > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    max-width: 130px;

    @media (min-width: ${Breakpoints.desktop}) {
      max-width: unset;
    }
  }
`

export const CountNumber = styled.p`
  color: ${Colors.red};
  font-size: 40px;
  font-weight: 700;

  @media (min-width: ${Breakpoints.desktop}) {
    font-size: 80px;
  }
`

export const CountDescription = styled.p`
  font-size: 25px;
  font-weight: 600;

  @media (min-width: ${Breakpoints.desktop}) {
    font-size: 32px;
  }
`

export const CasesSection = styled(props => <Width {...props} />)`
  margin-bottom: 5rem;

  @media (min-width: ${Breakpoints.desktop}) {
    margin-bottom: 12rem;
  }
`

export const CaseLink = styled(props => <LocalizedLink {...props} />)`
  & img {
    transform: scale(1);
    transition: transform 0.45s cubic-bezier(0.25, 0.5, 0.5, 1) 0s !important;
  }

  &:hover {
    & img {
      transform: scale(1.05);
    }
  }
`

export const CaseImage = styled(props => <Image {...props} />)`
  margin-top: auto;
  border-radius: 10px;
`

export const CasesWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 46px;
  margin-top: 5rem;

  @media (min-width: ${Breakpoints.desktop}) {
    grid-template-columns: 1fr 1fr;
    row-gap: 50px;
    column-gap: 16px;
  }

  & > a {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
  }
`

export const TextCaseWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const CaseTitle = styled.p`
  ${Type.body20};
  font-weight: 700;
  color: ${({ color }) => (color ? color : Colors.text)};
`

export const CaseDescription = styled.p`
  ${Type.body40};
  color: ${({ color }) => (color ? color : Colors.text)};
  margin: 12px 0px;
`

export const StyledArrow = styled(HoverArrow)`
  display: inline-block;
  height: 12px;
  width: 40px;
  white-space: nowrap;
  transform: scale(0.4);
  margin-left: -8px;
  padding-top: 3px;
  path {
    fill: ${props => props.color};
  }

  .arrow {
    left: -25px;
  }
`

export const StyledLink = styled(props => <LocalizedLink {...props} />)`
  ${Type.body30};
  color: ${Colors.navy};
  font-weight: 700;
  display: inline-block;
  padding: 20px 0;
  &:hover {
    color: ${Colors.navy};

    ${StyledArrow} {
      .arrow {
        left: 0px;
      }
    }
  }
`

export const DiagonalInner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: 3rem;

  @media (min-width: ${Breakpoints.desktop}) {
    margin-top: 0rem;
  }
`

export const SolveWithUsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 12rem;
  padding-top: 9rem;

  @media (min-width: ${Breakpoints.desktop}) {
    margin-bottom: 0rem;
    padding-top: 15rem;
  }
`

export const ResponsiveVideoContainer = styled.div`
  width: 100%;
  height: auto;
  margin-bottom: 3rem;
`
