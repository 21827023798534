import create from 'zustand'

const [useStore] = create((set, get) => {
  return {
    isNavOpen: false,
    locale: 'en-us',
    switchLocale: locale => set(() => ({ locale })),
    actions: {
      toggleNav() {
        const { isNavOpen } = get()
        set({ isNavOpen: !isNavOpen })
      },
    },
  }
})

export default useStore
