import styled from 'styled-components'

export const RightDescriptionComponent = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 110px;
  margin-bottom: 110px;
`

export const Text = styled.p`
  max-width: 411px;
  font-size: 25px;
  line-height: 33px;
`
