import React from 'react'
import ReactPlayer from 'react-player/lazy'
import * as S from './styles'

const SoundcloudPlayer = ({
  url = 'https://soundcloud.com/allturtlespodcast/when-ai-is-misaligned',
}) => {
  return (
    <S.PodcastPlayerComponent>
      <ReactPlayer
        url={url}
        className="react-player"
        width="100%"
        height="100%"
        config={{
          soundcloud: {
            options: {
              visual: false,
              preload: true,
              show_teaser: false,
              hide_related: true,
              auto_play: false,
              show_comments: false,
              show_reposts: false,
              show_artwork: false,
            },
          },
        }}
      />
    </S.PodcastPlayerComponent>
  )
}

export default SoundcloudPlayer
