import styled from 'styled-components'

import { Type } from '@/styles'
import Job from '../Job/Job'

export const JobDepartmentComponent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

export const Title = styled.h2`
  ${Type.subhead20};
  margin-bottom: 30px;
`

export const StyledJob = styled(Job)`
  margin-bottom: 30px;
`
