import * as React from 'react'

function Stitcher (props) {
  return (
    <svg width={66} height={67} viewBox='0 0 66 67' fill='none' {...props}>
      <path
        d='M0 33.888c0-18.247 14.754-33 33-33 18.246 0 33 14.753 33 33 0 18.246-14.754 33-33 33-18.246 0-33-14.754-33-33z'
        fill='#F2F2F2'
      />
      <g clipPath='url(#prefix__clip0)'>
        <path d='M17.369 27.59H9.035v15.272h8.334V27.591z' fill='#3792C4' />
        <path d='M27.34 24.845h-8.335V41.85h8.334V24.845z' fill='#622368' />
        <path d='M37.298 26.371h-8.335V43.86h8.335V26.37z' fill='#F9C616' />
        <path d='M47.253 23.674h-8.334v18.162h8.334V23.674z' fill='#EB8A23' />
        <path d='M57.208 26.965h-8.334v13.714h8.334V26.965z' fill='#C43727' />
      </g>
      <defs>
        <clipPath id='prefix__clip0'>
          <path
            fill='#fff'
            transform='translate(9.032 23.674)'
            d='M0 0h48.175v20.185H0z'
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default Stitcher
