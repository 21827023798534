import React from 'react'
import * as S from './styles'
import {
  Width,
  PodcastSourcesHeader,
  CaseStudyHeader,
  LocalizedLink,
} from '@/components'
import { Colors } from '@/styles'

const PodcastEpisodeHeader = ({
  episode,
  summary,
  title,
  seriesTitle,
  seriesSlug,
}) => {
  return (
    <>
      <CaseStudyHeader />
      <S.Hero bg={Colors.navy}>
        <Width>
          <S.Text color={Colors.white}></S.Text>
          {episode && (
            <S.Subhead color={Colors.white}>
              <LocalizedLink to="/podcast">Podcast</LocalizedLink> &gt;{' '}
              <LocalizedLink to={`/podcast/series/${seriesSlug}/`}>
                {seriesTitle}
              </LocalizedLink>{' '}
              &gt; EPISODE {episode}
            </S.Subhead>
          )}
          {title && <S.Title color={Colors.white}>{title.text}</S.Title>}
          {summary && (
            <S.Desc
              color={Colors.white}
              dangerouslySetInnerHTML={{ __html: summary.html }}
            />
          )}
          <PodcastSourcesHeader />
        </Width>
      </S.Hero>
    </>
  )
}

export default PodcastEpisodeHeader
