import React from 'react'
import {
  ApplePodcast,
  Soundcloud,
  Stitcher,
  Overcast,
  Spotify,
  GooglePlay,
  RssFeed,
  IHeartRadio,
} from '@/components/Icons/index'
import * as S from './styles'

const PodcastSourcesList = props => {
  return (
    <S.PodcastSourcesListComponent>
      <S.List>
        <S.ListItem>
          <S.StyledLink
            el="a"
            to="https://podcasts.apple.com/us/podcast/the-all-turtles-podcast/id1304493828"
          >
            <ApplePodcast role="presentation" />
            Apple Podcasts
          </S.StyledLink>
        </S.ListItem>
        <S.ListItem>
          <S.StyledLink el="a" to="https://soundcloud.com/allturtlespodcast">
            <Soundcloud role="presentation" />
            Soundcloud
          </S.StyledLink>
        </S.ListItem>
        <S.ListItem>
          <S.StyledLink
            el="a"
            to="https://www.stitcher.com/podcast/all-turtles/the-all-turtles-podcast"
          >
            <Stitcher role="presentation" />
            Stitcher
          </S.StyledLink>
        </S.ListItem>
        <S.ListItem>
          <S.StyledLink
            el="a"
            to="https://overcast.fm/itunes1304493828/the-all-turtles-podcast"
          >
            <Overcast role="presentation" />
            Overcast
          </S.StyledLink>
        </S.ListItem>
        <S.ListItem>
          <S.StyledLink
            el="a"
            to="https://open.spotify.com/show/1ajqipnGXHqKBaZqRiWIAe"
          >
            <Spotify role="presentation" />
            Spotify
          </S.StyledLink>
        </S.ListItem>
        <S.ListItem>
          <S.StyledLink
            el="a"
            to="https://podcasts.google.com/feed/aHR0cHM6Ly9hbGx0dXJ0bGVzLmxpYnN5bi5jb20vcnNz"
          >
            <GooglePlay role="presentation" />
            Google Play
          </S.StyledLink>
        </S.ListItem>
        <S.ListItem>
          <S.StyledLink
            el="a"
            to="http://feeds.feedburner.com/TheAllTurtlesPodcast"
          >
            <RssFeed role="presentation" />
            RSS Feed
          </S.StyledLink>
        </S.ListItem>
        <S.ListItem>
          <S.StyledLink
            el="a"
            to="https://www.iheart.com/podcast/263-the-all-turtles-pod-29037900/"
          >
            <IHeartRadio role="presentation" />
            iHeartRadio
          </S.StyledLink>
        </S.ListItem>
      </S.List>
    </S.PodcastSourcesListComponent>
  )
}

export default PodcastSourcesList
