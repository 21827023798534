import styled from 'styled-components'

import Arrow from '@/components/Icons/Arrow'

export const HoverArrowComponent = styled.div`
  position: relative;
  height: 30px;
  left: 4px;
`

export const StyledLeftEnd = styled(Arrow)`
  position: absolute;
  left: 0;
  top: 0;
`

export const LeftEndMask = styled.div`
  width: 6px;
  height: 30px;
  overflow: hidden;
  position: absolute;
  left: -2px;
  top: 0;
`

export const Mask = styled.div`
  width: 88px;
  overflow: hidden;
  position: absolute;
  left: 2px;
  top: 0;
  height: 30px;
`

export const StyledArrow = styled(Arrow)`
  position: absolute;
  left: -57px;
  top: 0;
  transition: all 200ms ease;
`
