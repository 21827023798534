module.exports = {
  pathPrefix: '/',
  title: `All Turtles`,
  description: `A mission-driven product studio`,
  author: `@allturtlesco`,
  siteUrl: `https://www.all-turtles.com`,
  // url: 'https://www.mmhmm.app', // No trailing slash!
  // logo: 'social.png', // Used for SEO
  // ogLanguage: 'en_US', // Facebook Language
  // skipNavId: 'reach-skip-nav', // ID for the "Skip to content" a11y feature
}
