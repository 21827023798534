import styled from 'styled-components'

export const PlayerComponent = styled.div`
  position: relative;
  padding-top: calc(
    480 / 640 * 100%
  ); /* height / width * 100% = calculate aspect ratio */
  .video-player {
    position: absolute;
    top: 0;
    left: 0;
  }
`
