import React from 'react'

import * as S from './styles'

const RightDescription = props => {
  return (
    <S.RightDescriptionComponent>
      <S.Text>{props.children}</S.Text>
    </S.RightDescriptionComponent>
  )
}

export default RightDescription
