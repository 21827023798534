import React from 'react'
import * as S from './styles'
import { LayoutRefactored } from '@/components/layout'
import {
  PodcastEpisodeHeader,
  PodcastEpisodeSidebar,
  SEO,
  DiagonalBox,
  SoundcloudPlayer,
  ResponsiveVideo,
  Width,
  Link,
  LocalizedLink,
  Foldingtext,
} from '@/components'

const PodcastEpisodePage = ({ data, pageContext }) => {
  if (!data) return null

  const {
    title,
    summary,
    participants,
    episode_links,
    highlights,
    episode,
    video,
    soundcloud_embed,
    social_image,
    serie,
    transcript,
    collapse_transcript,
  } = data.prismicPodcastepisode.data
  // const { uid } = data.prismicPodcastepisode

  const episodes = data.allPrismicPodcastepisode
    ? data.allPrismicPodcastepisode.edges
    : []

  const seriesTitle = serie.uid
    .replace(/-/g, ' ')
    .replace(/\w\S*/g, w => w.replace(/^\w/, c => c.toUpperCase()))
  const seriesSlug = serie.uid

  const episodeLine = episode ? `Episode ${episode} – ` : ' '

  return (
    <LayoutRefactored locale={pageContext.lang}>
      <S.PodcastEpisodePageComponent>
        <SEO
          title={`${seriesTitle} | ${episodeLine} ${title.text}`}
          description={summary.text}
          image={social_image.url}
        />

        {/* Pass episode header data/props to component below. */}
        <PodcastEpisodeHeader
          title={title}
          episode={episode}
          summary={summary}
          seriesSlug={seriesSlug}
          seriesTitle={seriesTitle}
          lang="en"
        />
        {/* Soundcloud embed */}
        {soundcloud_embed && (
          <Width>
            <S.SoundcloudEmbed>
              <SoundcloudPlayer url={soundcloud_embed.embed_url} />
            </S.SoundcloudEmbed>
          </Width>
        )}

        <DiagonalBox bg="white">
          <S.ContentWrapper>
            <S.ContentMain lang="en">
              {/* Section A - Overview */}
              {video.embed_url && (
                <>
                  <S.Title>Overview</S.Title>
                  <S.ResponsiveVideoContainer>
                    <ResponsiveVideo url={video.embed_url} />
                  </S.ResponsiveVideoContainer>
                </>
              )}

              {/* Section B - Overview */}
              {participants && participants.length > 1 && (
                <>
                  <S.Title>In this episode</S.Title>
                  <S.GuestList>
                    {participants.map((participant, i) => (
                      <S.Guest key={`guest-${i}`}>
                        {participant.image.url && (
                          <S.GuestAvatar
                            src={participant.image.url}
                            alt={participant.name.text}
                          />
                        )}
                        <S.GuestInfo>
                          {participant.name.text &&
                            (participant.link ? (
                              <Link to={participant.link.url} el="a">
                                <S.GuestName>
                                  {participant.name.text}
                                </S.GuestName>
                              </Link>
                            ) : (
                              <S.GuestName>{participant.name.text}</S.GuestName>
                            ))}
                          {participant.bio.html && (
                            <S.GuestDesc
                              dangerouslySetInnerHTML={{
                                __html: participant.bio.html,
                              }}
                            />
                          )}
                        </S.GuestInfo>
                      </S.Guest>
                    ))}
                  </S.GuestList>
                </>
              )}

              {highlights && highlights.length > 1 && (
                <>
                  {/* Section C - Highlights */}
                  <S.Title>Highlights</S.Title>
                  {highlights.map((highlight, i) => (
                    <S.HighlightQuote key={`highlight-${i}`}>
                      {highlight.highlight.text && highlight.highlight.text}
                      {highlight.highlight_time && (
                        <S.HighlightQuoteTime>{` ${highlight.highlight_time}`}</S.HighlightQuoteTime>
                      )}
                      {highlight.highlight_source.text && (
                        <S.HighlightQuoteSource>{` ${highlight.highlight_source.text}`}</S.HighlightQuoteSource>
                      )}
                    </S.HighlightQuote>
                  ))}
                </>
              )}

              {/* <S.TranscriptButton
                to={`/podcast/series/culture-fit/${uid}/transcript`}
                margin="0 0 150px"
              >
                View Full Transcript
              </S.TranscriptButton> */}

              {episode_links && episode_links.length > 1 && (
                <>
                  {/* Section C - episode_links */}
                  <S.Title>Episode Links</S.Title>
                  {episode_links.map((link, i) => (
                    <S.EpisodeLink key={`episode-${i}`}>
                      <S.EpisodeNumber>{i + 1}</S.EpisodeNumber>
                      <S.EpisodeDetails>
                        {link.url.url ? (
                          <LocalizedLink to={link.url.url} el="a">
                            <S.EpisodeTitle>
                              {link.link_title.text}
                            </S.EpisodeTitle>
                          </LocalizedLink>
                        ) : (
                          <S.EpisodeTitle>
                            {link.link_title.text}
                          </S.EpisodeTitle>
                        )}
                        <S.EpisodeDesc
                          dangerouslySetInnerHTML={{
                            __html: link.link_description.html,
                          }}
                        ></S.EpisodeDesc>
                      </S.EpisodeDetails>
                      <S.EpisodeIcon
                        src={link.link_image.url}
                        alt={link.link_title.text}
                      />
                    </S.EpisodeLink>
                  ))}
                </>
              )}

              {/* Transcript */}
              {transcript.html && (
                <>
                  <S.Title>Show notes</S.Title>
                  {!collapse_transcript ? (
                    <S.Transcript
                      dangerouslySetInnerHTML={{
                        __html: transcript.html,
                      }}
                    />
                  ) : (
                    <Foldingtext html={transcript.html} />
                  )}
                </>
              )}
            </S.ContentMain>
            <S.ContentSidebar>
              {/* Pass episode sidebar data/props to component below. */}
              <PodcastEpisodeSidebar episodes={episodes} serie={serie.uid} />
            </S.ContentSidebar>
          </S.ContentWrapper>
        </DiagonalBox>
      </S.PodcastEpisodePageComponent>
    </LayoutRefactored>
  )
}

export default PodcastEpisodePage
