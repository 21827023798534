import React, { useRef } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { useSpring, useTransition, useChain, config } from 'react-spring'
import { useScrollPosition } from '@n8tb1t/use-scroll-position'
import useStore from '@/store/'
import { LocalizedLink } from '@/components'
import { Plus } from '@/components/Icons'
import * as S from './styles'
import locales from '../../../config/i18n'

const Navigation = props => {
  const { isHomePage, isAboutATPage } = props

  const [verticalScroll, setVerticalScroll] = React.useState(0)

  useScrollPosition(({ currPos }) => {
    setVerticalScroll(currPos.y)
  })

  const isNavOpen = useStore(state => state.isNavOpen)
  const actions = useStore(state => state.actions)
  const locale = useStore(state => state.locale)
  const oppositeLocale = locales[locale === 'en-us' ? 'uk-ua' : 'en-us']
  const navDataResult = useStaticQuery(graphql`
    {
      allPrismicNavMenu {
        nodes {
          lang
          data {
            menu_title {
              text
            }
            close_icon_alt {
              text
            }
            open_icon_alt {
              text
            }
            nav {
              nav_slug
              nav_text {
                text
              }
            }
          }
        }
      }
    }
  `)
  const navData = navDataResult.allPrismicNavMenu.nodes.find(
    node => node.lang === locale
  )?.data

  // container
  const springRef = useRef()
  const { size, opacity, ...rest } = useSpring({
    ref: springRef,
    config: config.stiff,
    from: { size: '0px' },
    to: {
      size: isNavOpen ? '150px' : '0px',
    },
  })

  // items
  const navItemsMobile = [...navData?.nav]
  navItemsMobile.push({
    nav_text: {
      text: oppositeLocale.title,
    },
  })

  const transRef = useRef()
  const transitions = useTransition(
    isNavOpen ? navItemsMobile : [],
    item => item.nav_text.text,
    {
      ref: transRef,
      unique: true,
      trail: 400 / navItemsMobile.length,
      from: { opacity: 0, transform: 'scale(0)' },
      enter: { opacity: 1, transform: 'scale(1)' },
      leave: { opacity: 0, transform: 'scale(0)' },
    }
  )

  const rotate = useSpring({
    transform: isNavOpen ? 'rotate(0deg)' : 'rotate(135deg)',
    config: config.wobbly,
  })

  // timeline
  useChain(isNavOpen ? [springRef, transRef] : [transRef, springRef], [
    0.1,
    isNavOpen ? 0.1 : 0.6,
  ])

  let currentPath = ''
  if (typeof window !== 'undefined') {
    currentPath = window.location.pathname
  }

  return (
    <S.NavigationComponent>
      <S.WordmarkContainer fixed={!isHomePage} verticalScroll={verticalScroll}>
        {isHomePage ? (
          <S.StyledWordmark small alt="All Turtles" />
        ) : (
          <LocalizedLink
            to="/"
            onClick={() => (isNavOpen ? actions.toggleNav() : null)}
          >
            {isAboutATPage ? (
              <S.StyledLogo color="#EB4646" alt="All Turtles" />
            ) : (
              <S.StyledWordmark small alt="All Turtles" />
            )}
          </LocalizedLink>
        )}
      </S.WordmarkContainer>

      <div style={{ flex: 1 }} />

      <S.Mobile>
        <S.Toggle
          onClick={() => actions.toggleNav()}
          aria-label={
            navData?.[`${isNavOpen ? 'close' : 'open'}_icon_alt`]?.text ?? ''
          }
        >
          <Plus title={navData?.menu_title?.text} style={rotate} />
        </S.Toggle>
        <S.Container
          style={{ ...rest, width: '100%', height: size }}
          onClick={() => actions.toggleNav()}
        >
          {transitions.map(({ item, key, props }, index) => {
            if (index === transitions.length - 1) {
              return (
                <S.Item key={`transition-${key}`} style={{ ...props }}>
                  <S.StyledLocaleMobileLink
                    lang={oppositeLocale.path}
                    to={`/${
                      oppositeLocale.isDefault
                        ? currentPath.replace('/uk/', '')
                        : `${oppositeLocale.path}${currentPath}`
                    }`}
                  >
                    {oppositeLocale.title}
                  </S.StyledLocaleMobileLink>
                </S.Item>
              )
            }
            return (
              <S.Item key={`transition-${key}`} style={{ ...props }}>
                <LocalizedLink to={item?.nav_slug}>
                  {item?.nav_text?.text}
                </LocalizedLink>
              </S.Item>
            )
          })}
        </S.Container>
      </S.Mobile>
      <S.Desktop>
        {navData?.nav?.map((el, i) => (
          <S.StyledLink key={`link-${i}`} to={el.nav_slug}>
            {el.nav_text.text}
          </S.StyledLink>
        ))}
        <S.StyledLocaleLink
          lang={oppositeLocale.path}
          to={`/${
            oppositeLocale.isDefault
              ? currentPath.replace('/uk/', '')
              : `${oppositeLocale.path}${currentPath}`
          }`}
        >
          {oppositeLocale.title}
        </S.StyledLocaleLink>
      </S.Desktop>
    </S.NavigationComponent>
  )
}

export default Navigation
