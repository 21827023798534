import React from 'react'

import * as S from './styles'

const JobDepartment = ({ title, jobs, ...rest }) => {
  return (
    <S.JobDepartmentComponent {...rest}>
      <S.Title>{title}</S.Title>
      {jobs.map((item, index) => (
        <S.StyledJob {...item} key={`job-item-${index}`} />
      ))}
    </S.JobDepartmentComponent>
  )
}

export default JobDepartment
