import styled from 'styled-components'
import { Type, Colors } from '@/styles'

export const Text = styled.p`
  ${Type.body40};
  color: ${Colors.white};
  a {
    color: ${Colors.offWhite};
    text-decoration: underline;
    :hover {
      color: ${Colors.white};
    }
  }
`
