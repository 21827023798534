import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'

import useStore from '@/store/'
import locales from '../../../config/i18n'
import * as S from './styles'
import {
  GlobalStyle,
  CaseStudyHeader,
  Footer,
  SEO,
  Cookies,
} from '@/components'

const JobDetailPage = props => {
  const locale = props.pageContext.lang
  const language = useStore(state => state.locale)
  const switchLocale = useStore(state => state.switchLocale)

  useEffect(() => {
    if (locale && locale !== language) {
      switchLocale(locale)
    }
  }, [locale, language, switchLocale])

  useEffect(() => {
    if (!window || !document) return
    const greenhouseJobId = props.pageContext.gh_Id
    let script = document.createElement('script')
    script.src =
      'https://boards.greenhouse.io/embed/job_board/js?for=allturtles'
    script.async = true
    script.onload = () => {
      window.Grnhse.Iframe.load(greenhouseJobId)
    }
    document.body.appendChild(script)
  })

  return (
    <S.JobDetailPageComponent>
      <Helmet htmlAttributes={{ lang: locales[locale].path || 'en' }} />
      <SEO
        title={props.pageContext.title}
        description={props.pageContext.seoDescription}
      />
      <GlobalStyle />
      <CaseStudyHeader />
      <S.StyledWidth>
        <S.GreenhouseApp id="grnhse_app" />
      </S.StyledWidth>
      <Footer />
      <Cookies />
    </S.JobDetailPageComponent>
  )
}

export default JobDetailPage
