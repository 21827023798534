import React from 'react'
import * as S from './styles'
import Img from 'gatsby-image'
import { LayoutRefactored } from '@/components/layout'
import {
  PodcastSourcesList,
  CaseStudyHeader,
  SEO,
  DiagonalBox,
  Width,
  LocalizedLink,
} from '@/components'
import { Colors } from '@/styles'

const PodcastPage = ({ data, pageContext }) => {
  if (!data) return null

  const series = data.allPrismicPodcastserie.edges
  const episodes = data.allPrismicPodcastepisode.edges
  const {
    summary,
    subhead,
    title,
    social_image,
    series_heading,
    episodes_heading,
    sidebar_heading,
    more_details,
  } = data.prismicPodcastpage.data

  // Order episodes by most recent sort date.
  episodes.sort((a, b) => a.node.data.sort_date - b.node.data.sort_date)

  return (
    <LayoutRefactored locale={pageContext.lang}>
      <S.PodcastPageComponent>
        <SEO
          title={title.text}
          description={title.summary}
          image={social_image.url}
        />
        <CaseStudyHeader />
        <S.Hero bg={Colors.navy}>
          <Width>
            {subhead?.text && (
              <S.Subhead color={Colors.white}>{subhead.text}</S.Subhead>
            )}
            {title.text && <S.Title color={Colors.white}>{title.text}</S.Title>}
            {summary?.text && (
              <S.Text color={Colors.white}>{summary.text}</S.Text>
            )}
          </Width>
          <Width>
            <S.Subhead color={Colors.white}>{series_heading.text}</S.Subhead>
            <S.SeriesList>
              {series.map(serie => {
                return (
                  <li key={serie.node.uid}>
                    <LocalizedLink to={`/podcast/series/${serie.node.uid}`}>
                      <Img
                        alt={serie.node.data.title.text}
                        fluid={serie.node.data.image.fluid}
                      />
                    </LocalizedLink>
                  </li>
                )
              })}
            </S.SeriesList>
          </Width>
        </S.Hero>
        <DiagonalBox bg="white">
          <S.ContentWrapper>
            <S.ContentMain>
              {episodes_heading?.text && (
                <S.TitleH4>{episodes_heading.text}</S.TitleH4>
              )}

              {episodes.map((ep, i) => {
                const {
                  episode,
                  duration,
                  summary,
                  title,
                  serie,
                  disable_link,
                  meta_release_date,
                } = ep.node.data
                const { uid } = ep.node

                return (
                  <S.EpisodePreview key={`episode-prev-${i}`} lang="en">
                    <S.EpisodeInfo>
                      {serie.uid ?? ''}
                      {` | `}
                      {episode ? `ep. ${episode}` : ''}{' '}
                      {duration ? `| ${duration}` : ''}
                    </S.EpisodeInfo>
                    {title.text &&
                      (disable_link === true ? (
                        <>
                          {title.text && (
                            <S.EpisodeTitle>{title.text}</S.EpisodeTitle>
                          )}
                          {meta_release_date.text && (
                            <S.EpisodeReleaseDate>
                              {meta_release_date.text}
                            </S.EpisodeReleaseDate>
                          )}
                        </>
                      ) : (
                        <LocalizedLink
                          to={`/podcast/series/${serie.uid}/${uid}`}
                          hover={Colors.red}
                        >
                          <S.EpisodeTitle>{title.text}</S.EpisodeTitle>
                        </LocalizedLink>
                      ))}
                    {summary.html && (
                      <S.EpisodeExcerpt
                        dangerouslySetInnerHTML={{ __html: summary.html }}
                      />
                    )}
                    {!disable_link && more_details?.text && (
                      <S.DetailsLink to={`/podcast/series/${serie.uid}/${uid}`}>
                        {more_details.text}
                      </S.DetailsLink>
                    )}
                  </S.EpisodePreview>
                )
              })}
            </S.ContentMain>
            <S.ContentSidebar>
              {sidebar_heading?.text && (
                <S.Subhead>{sidebar_heading.text}</S.Subhead>
              )}
              <PodcastSourcesList />
            </S.ContentSidebar>
          </S.ContentWrapper>
        </DiagonalBox>
      </S.PodcastPageComponent>
    </LayoutRefactored>
  )
}

export default PodcastPage
