import styled from 'styled-components'
import { Link } from '@/components'
import { Breakpoints, Type, Colors } from '@/styles'

export const PodcastEpisodePageComponent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`

/* --------------------
Typography
-------------------- */

export const Title = styled.h2`
  ${Type.header4};
  color: ${({ color }) => (color ? color : Colors.text)};
  margin-bottom: 10px;
  @media (min-width: ${Breakpoints.desktop}) {
    margin-bottom: 35px;
    max-width: 674px;
  }
`

export const Text = styled.div`
  ${Type.body40};
  margin-bottom: 0;
  color: ${Colors.navy};
`

/* --------------------
Content
-------------------- */

export const ContentWrapper = styled.div`
  padding-top: 50px;
  padding-bottom: 50px;
  width: 100%;
  @media (min-width: ${Breakpoints.tablet}) {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-gap: 20px;
  }
  @media (min-width: ${Breakpoints.desktop}) {
    padding-top: 120px;
    padding-bottom: 120px;
  }
`

export const ContentMain = styled.div`
  @media (min-width: ${Breakpoints.tablet}) {
    grid-column: 1 / span 8;
    padding-top: 12px;
    align-self: start;
  }
`

export const ContentSidebar = styled.div`
  @media (min-width: ${Breakpoints.tablet}) {
    grid-column: 10 / span 3;
    align-self: start;
    margin-top: 20px;
  }
`

/* --------------------
Guest (in this episode)
-------------------- */

export const GuestList = styled.section`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 50px;
  margin-bottom: 150px;
  @media (min-width: ${Breakpoints.tablet}) {
    grid-template-columns: repeat(2, 1fr);
  }
`

export const Guest = styled.div`
  display: flex;
  align-items: center;
`

export const GuestAvatar = styled.img`
  border-radius: 45px;
  margin-right: 16px;
`

export const GuestInfo = styled.div`
  a {
    color: ${Colors.navy};
    :hover {
      color: ${Colors.red};
    }
  }
`

export const GuestName = styled.p`
  font-size: 18px;
  font-weight: ${Type.fontWeights.bold};
  margin-bottom: 3px;
`

export const GuestDesc = styled.span`
  font-size: 12px;
  margin-bottom: 0;
  color: ${Colors.navy};
`

/* --------------------
Highlights
-------------------- */

export const HighlightQuote = styled.blockquote`
  ${Type.body40};
  color: ${Colors.navy};
  border-top: 1px solid ${Colors.black};
  padding-top: 16px;
  margin: 0 0 50px;
  :last-of-type {
    margin-bottom: 150px;
  }
`

export const HighlightQuoteTime = styled.span`
  color: #999;
`

export const HighlightQuoteSource = styled.p`
  ${Type.subhead20};
  margin: 12px 0 0;
`

/* --------------------
Episode Links
-------------------- */

export const EpisodeLink = styled.section`
  display: grid;
  grid-template-columns: 60px 1fr 60px;
  border-top: 1px solid ${Colors.black};
  padding-top: 16px;
  margin-bottom: 50px;
  :last-of-type {
    margin-bottom: 150px;
  }
`

export const EpisodeNumber = styled.p`
  justify-self: center;
  align-self: start;
  font-size: 15px;
  font-weight: ${Type.fontWeights.bold};
  color: ${Colors.navy};
  margin: 3px 0 0;
`

export const EpisodeDetails = styled.div`
  align-self: start;
  color: ${Colors.navy};
`

export const EpisodeTitle = styled.h3`
  ${Type.body30};
  font-weight: ${Type.fontWeights.regular};
  color: ${Colors.navy};
  margin: 0;
  transition: color 0.2s ease-in-out;
  &:hover {
    color: ${Colors.red};
  }
`

export const EpisodeDesc = styled.p`
  ${Type.body40};
  color: ${Colors.navy};
  margin: 6px 0 0;
`

export const EpisodeIcon = styled.img`
  justify-self: center;
  align-self: start;
  height: auto;
  width: 64px;
  margin: 3px 0 0;
`

/* --------------------
Transcript Button
-------------------- */

export const TranscriptButton = styled(Link)`
  display: inline-block;
  padding: 12px 30px;
  background: transparent;
  border: 2px solid ${Colors.navy};
  border-radius: 40px;
  font-size: 16px;
  font-weight: ${Type.fontWeights.regular};
  color: ${Colors.navy};
  margin: ${props => props.margin || '0'};
  transition: all 0.15s ease-in-out;
  &:hover {
    color: ${Colors.white};
    background: ${Colors.navy};
  }
`

/* --------------------
Soundcloud Embed
-------------------- */

export const SoundcloudEmbed = styled.div`
  position: relative;
  z-index: 5;
  max-width: 640px;
`

/* --------------------
Video Embed
-------------------- */
export const ResponsiveVideoContainer = styled.div`
  width: 100%;
  height: auto;
  margin-bottom: 150px;
`

/* --------------------
Transcript
-------------------- */

export const Transcript = styled.div`
  ${Type.body40};
  margin-bottom: 0;
  margin-top: 20px;
  color: ${Colors.navy};
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    ${Type.body40};
    margin-top: 20px;
  }
  p:not(:last-of-type) {
    margin-bottom: 20px;
  }
`
