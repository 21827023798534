import * as React from 'react'

function Spotify (props) {
  return (
    <svg width={66} height={66} viewBox='0 0 66 66' fill='none' {...props}>
      <path
        d='M0 33C0 14.754 14.754 0 33 0c18.246 0 33 14.754 33 33 0 18.246-14.754 33-33 33C14.754 66 0 51.246 0 33z'
        fill='#F2F2F2'
      />
      <path
        d='M33.2 12.044c-11.366 0-20.553 9.186-20.553 20.552S21.834 53.15 33.2 53.15c11.366 0 20.552-9.187 20.552-20.553C53.596 21.23 44.41 12.044 33.2 12.044zm9.342 29.583c-.467.623-1.09.778-1.713.467-4.827-2.958-10.899-3.581-18.061-2.024-.779.155-1.401-.312-1.557-.934-.156-.779.311-1.402.934-1.557 7.785-1.713 14.48-1.09 19.93 2.18.622.466.778 1.245.467 1.868zm2.49-5.605c-.466.778-1.4 1.09-2.179.467-5.605-3.426-13.857-4.36-20.397-2.336-.778.156-1.712-.155-1.868-1.09-.156-.778.156-1.712 1.09-1.868 7.474-2.18 16.66-1.09 23.043 2.803.468.31.779 1.245.312 2.024zm.157-5.761c-6.54-3.893-17.439-4.36-23.667-2.336-1.09.312-2.18-.311-2.49-1.245-.312-1.09.31-2.024 1.245-2.336 7.318-2.18 19.306-1.713 26.78 2.803.934.467 1.246 1.713.779 2.647-.468.622-1.713.934-2.647.467z'
        fill='#1ED760'
      />
    </svg>
  )
}

export default Spotify
