import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { LocalizedLink } from '@/components'
import useStore from '@/store/'
import * as S from './styles'

const PodcastEpisodeSidebar = ({ serie = 'culture-fit', episodes = [] }) => {
  const locale = useStore(state => state.locale)
  const data = useStaticQuery(graphql`
    query sidebarQuery {
      allPrismicPodcastserie {
        edges {
          node {
            lang
            data {
              image {
                url
                fluid(maxWidth: 600) {
                  ...GatsbyPrismicImageFluid
                }
              }
              summary {
                html
              }
              title {
                text
              }
            }
            uid
          }
        }
      }
    }
  `)
  if (!data.allPrismicPodcastserie) return null

  const allSeries = data.allPrismicPodcastserie.edges
  const filtered = allSeries.filter(
    item => item.node.lang === locale && item.node.uid === serie
  )

  const { image, summary, title } = filtered[0].node.data

  return (
    <>
      {image.url && <S.SeriesImg fluid={image.fluid} alt={title.text} />}
      {title.text && <S.SeriesTitle>{title.text}</S.SeriesTitle>}
      {summary.html && (
        <S.SeriesDescription
          dangerouslySetInnerHTML={{ __html: summary.html }}
        />
      )}
      {episodes && episodes.length > 0 && (
        <>
          <S.SeriesSubhead lang="en">
            Episodes ({episodes.length})
          </S.SeriesSubhead>
          <S.SeriesEpisodes lang="en">
            {episodes
              .sort(function (a, b) {
                if (a.node.data.sort_date < b.node.data.sort_date) return 1
                if (a.node.data.sort_date > b.node.data.sort_date) return -1
                return 0
              })
              .slice(0, 10)
              .map((episode, index) => {
                const { title, disable_link } = episode.node.data
                const { uid } = episode.node
                const output = !disable_link ? (
                  <S.SeriesEpisode key={`series-episode-${index}`}>
                    <LocalizedLink to={`/podcast/series/${serie}/${uid}`}>
                      {title.text}
                    </LocalizedLink>
                  </S.SeriesEpisode>
                ) : null
                return output
              })}
          </S.SeriesEpisodes>
          <S.SeriesAllEpisodes lang="en">
            <LocalizedLink to={`/podcast/series/${serie}`}>
              View all
            </LocalizedLink>
          </S.SeriesAllEpisodes>
        </>
      )}
    </>
  )
}

export default PodcastEpisodeSidebar
