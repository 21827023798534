import React from 'react'

const ShortArrow = props => {
  return (
    <svg
      width='35'
      height='30'
      viewBox='0 0 35 30'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M21.6863 0.857883L34.4142 13.5858C35.1953 14.3669 35.1953 15.6332 34.4142 16.4142L21.6863 29.1422C20.9052 29.9232 19.6389 29.9232 18.8579 29.1422C18.0768 28.3611 18.0768 27.0948 18.8579 26.3137L28.1716 17L2 17C0.895429 17 -1.38412e-06 16.1046 -1.28756e-06 15C-1.19099e-06 13.8954 0.895429 13 2 13L28.1716 13L18.8579 3.68631C18.0768 2.90526 18.0768 1.63893 18.8579 0.857883C19.6389 0.0768342 20.9052 0.0768344 21.6863 0.857883Z'
        fill='#313C49'
      />
    </svg>
  )
}

export default ShortArrow
