import React from 'react'
import styled from 'styled-components'
import Image from 'gatsby-image'

import HoverArrow from '../HoverArrow/HoverArrow'
import Width from '../Width/Width'
import { Breakpoints, Type } from '@/styles'
import fontColorMap from '@/data/fontColorMap'
import LocalizedLink from '../LocalizedLink/LocalizedLink'

export const CaseStudyCardComponent = styled.div`
  height: 565px;
  display: flex;
  justify-content: center;
  position: relative;
  margin-bottom: 150px;

  @media (min-width: ${Breakpoints.tablet}) {
    height: 676px;
    margin-bottom: -150px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  @media (min-width: ${Breakpoints.desktop}) {
    height: 1036px;
    margin-bottom: -300px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`

export const Text = styled.div`
  align-items: center;
  display: none;

  @media (min-width: ${Breakpoints.tablet}) {
    display: flex;
  }
`

export const TextMobile = styled.div`
  align-items: center;
  display: flex;
  width: 100%;
  position: relative;
  z-index: 3;

  @media (min-width: ${Breakpoints.tablet}) {
    display: none;
  }
`

export const StyledImage = styled(Image)`
  width: 380px;
  height: 380px;
  align-self: center;
  margin-top: -110px;
  margin-left: -26px;
  flex-shrink: 0;
  width: 300px;
  height: 300px;

  @media (min-width: ${Breakpoints.tablet}) {
    width: 280px;
    height: 280px;
    align-self: flex-start;
    margin-left: -120px;
    margin-right: 8px;
    margin-top: 40px;
    flex-shrink: 1;
  }

  @media (min-width: ${Breakpoints.desktop}) {
    width: 450px;
    height: 450px;
    margin-left: -115px;
    margin-right: 0;
    margin-top: 30px;
  }
`

export const StyledLink = styled(props => <LocalizedLink {...props} />)`
  @media (min-width: ${Breakpoints.desktop}) {
    left: 50px;
    position: relative;
    padding-right: 50px;
  }
`

export const StyledArrow = styled(HoverArrow)`
  margin-top: 2px;

  .arrow {
    left: -25px;
  }

  path {
    fill: ${fontColorMap.dark};

    @media (min-width: ${Breakpoints.tablet}) {
      fill: ${props => fontColorMap[props.color]};
    }
  }
`

export const TextWrap = styled.div`
  position: relative;
  margin-top: 12px;

  @media (min-width: ${Breakpoints.tablet}) {
    margin-top: 30px;
  }

  @media (min-width: ${Breakpoints.desktop}) {
    margin-top: 55px;
  }

  &:hover {
    ${StyledArrow} {
      .arrow {
        left: 0px;
      }
    }
  }
`

export const Title = styled.h2`
  margin-bottom: 12px;
  color: ${fontColorMap.dark};

  @media (min-width: ${Breakpoints.tablet}) {
    color: ${props => fontColorMap[props.fontColor]};
  }
`

export const Description = styled.p`
  ${Type.body30};
  max-width: 296px;
  margin-bottom: 10px;
  color: ${fontColorMap.dark};

  @media (min-width: ${Breakpoints.tablet}) {
    margin-bottom: 32px;
    max-width: 360px;
    color: ${props => fontColorMap[props.fontColor]};
  }
`

export const Rectangle = styled.div`
  position: absolute;
  height: 160px;
  width: 100vw;
  background-color: ${props => props.color};
  z-index: 2;
  transform: skewY(-9.85deg);
  left: 0;
  top: 0;

  @media (min-width: ${Breakpoints.tablet}) {
    flex-direction: row;
    height: 247px;
  }

  @media (min-width: ${Breakpoints.desktop}) {
    flex-direction: row;
    height: 370px;
  }
`

export const ClipImage = styled.div`
  position: absolute;
  height: 160px;
  width: 100vw;
  z-index: 5;
  transform: skewY(-9.85deg);
  left: 0;
  overflow: hidden;
  display: flex;
  justify-content: center;
  top: -175px;
  padding-top: 175px;

  @media (min-width: ${Breakpoints.tablet}) {
    flex-direction: row;
    height: 247px;
    top: -120px;
    padding-top: 120px;
  }

  @media (min-width: ${Breakpoints.desktop}) {
    flex-direction: row;
    height: 370px;
    top: -200px;
    padding-top: 200px;
  }
`

export const RectMask = styled.div`
  width: 100vw;
  height: 705px;
  position: absolute;
  top: -134px;

  @media (min-width: ${Breakpoints.tablet}) {
    height: 872px;
    top: -120px;
  }

  @media (min-width: ${Breakpoints.desktop}) {
    height: 705px;
    top: -15%;
  }
`

export const OpacityRectangle = styled(Rectangle)`
  position: absolute;
  z-index: 2;
  opacity: 0.2;
  background-color: ${props => props.color};
  transform: skewY(9deg);
  top: 200px;

  @media (max-width: ${Breakpoints.tablet}) {
    height: 450px;
    top: 150px;
  }
`

export const StyledWidth = styled(Width)`
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 3;
  transform: skewY(9.85deg);

  @media (min-width: ${Breakpoints.tablet}) {
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
  }
`
