import React from 'react'

import * as S from './styles'
import { TextPage } from '@/components'

/**
 * NEED i18n?
 */

const PrivacyPolicy = props => {
  return (
    <TextPage title="Privacy Policy">
      <S.StyledPrivacy />
    </TextPage>
  )
}

export default PrivacyPolicy
