import styled from 'styled-components'

export const TrademarksContent = styled.div`
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  ul:last-of-type {
    margin-top: 30px;
    li:not(:first-of-type) {
      margin-top: 50px;
    }
  }
`
