import styled, { css } from 'styled-components'

import { Breakpoints } from '@/styles'
import { Twitter, Facebook, LinkedIn, Dribbble } from '../Icons'
import Link from '../Link/Link'

export const SocialsComponent = styled.div`
  margin-bottom: 28px;

  @media (min-width: ${Breakpoints.desktop}) {
    margin-bottom: 0;
  }
`

export const StyledLink = styled(Link)`
  margin-left: 15px;

  &:first-child {
    margin-left: 0;
  }

  @media (min-width: ${Breakpoints.tablet}) {
    margin-left: 20px;
  }
`

const iconStyles = css`
  opacity: 0.5;
  transition: opacity 200ms ease;
  width: 35px;
  height: 35px;

  &:hover {
    opacity: 1;
  }
`

export const StyledTwitter = styled(Twitter)`
  ${iconStyles};
`

export const StyledFacebook = styled(Facebook)`
  ${iconStyles};
`

export const StyledLinkedIn = styled(LinkedIn)`
  ${iconStyles};
`

export const StyledDribbble = styled(Dribbble)`
  ${iconStyles};
`
