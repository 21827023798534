import * as React from 'react'

const Dribbble = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 36 36"
    {...props}
  >
    <title>Dribbble</title>
    <g fill="#fff" clipPath="url(#dribble-l3fgwjah0c)">
      <path d="M17.894 35.257A17.47 17.47 0 1 1 35.36 17.792a17.484 17.484 0 0 1-17.465 17.465Zm0-33.058A15.593 15.593 0 1 0 33.48 17.792 15.612 15.612 0 0 0 17.894 2.199Z" />
      <path
        fillRule="evenodd"
        d="M18 8C12.48 8 8 12.48 8 18s4.48 10 10 10c5.51 0 10-4.48 10-10S23.51 8 18 8Zm6.605 4.61a8.502 8.502 0 0 1 1.93 5.314c-.281-.054-3.101-.629-5.943-.271-.065-.141-.12-.293-.184-.445a25.416 25.416 0 0 0-.564-1.236c3.145-1.28 4.577-3.124 4.761-3.362ZM18 9.475c2.17 0 4.154.814 5.662 2.148-.152.216-1.443 1.941-4.48 3.08-1.399-2.57-2.95-4.675-3.188-5A8.687 8.687 0 0 1 18 9.475Zm-3.633.803a53.867 53.867 0 0 1 3.167 4.935c-3.992 1.063-7.517 1.04-7.896 1.04a8.581 8.581 0 0 1 4.729-5.975ZM9.453 18.01v-.26c.37.01 4.512.065 8.775-1.215.25.477.477.965.694 1.453-.109.033-.228.065-.336.098-4.404 1.42-6.746 5.303-6.942 5.629a8.521 8.521 0 0 1-2.19-5.705ZM18 26.547a8.482 8.482 0 0 1-5.239-1.8c.152-.315 1.888-3.656 6.703-5.337.022-.01.033-.01.054-.022a35.318 35.318 0 0 1 1.823 6.475 8.4 8.4 0 0 1-3.341.684Zm4.761-1.465c-.086-.52-.542-3.015-1.659-6.084 2.679-.423 5.022.271 5.314.369a8.468 8.468 0 0 1-3.655 5.715Z"
        clipRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="dribble-l3fgwjah0c">
        <path fill="#fff" d="M.424.321H35.36v34.936H.424z" />
      </clipPath>
    </defs>
  </svg>
)

export default Dribbble
