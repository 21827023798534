import React from 'react'
import * as S from './styles'
import Layout from '@/components/layout'
import { Colors } from '@/styles'
import { CaseStudyHeader, DiagonalBox, JobList, Width, SEO } from '@/components'

// TODO: Remove this if we don't end up using `content`
// function decodeHTMLEntities(text) {
//   var textArea = document.createElement('textarea');
//   textArea.innerHTML = text;
//   return textArea.value;
// }

const Careers = ({
  pageContext: {
    lang,
    data: allPrismicCareers,
    jobs,
    meta: { social_image },
  },
}) => {
  return (
    <Layout locale={lang}>
      <S.CareersComponent>
        <SEO
          title="Careers"
          description={
            allPrismicCareers.main_title?.text ||
            'Let�s turn great ideas into impactful products.'
          }
          image={social_image?.url}
        />
        <CaseStudyHeader />
        <Width>
          {allPrismicCareers.main_title && (
            <S.Title>{allPrismicCareers.main_title.text}</S.Title>
          )}
          {allPrismicCareers.main_description && (
            <S.Description
              dangerouslySetInnerHTML={{
                __html: allPrismicCareers.main_description.html,
              }}
            />
          )}
        </Width>
        <DiagonalBox bg={Colors.lightGray} color="unset">
          {allPrismicCareers.secondary_title && (
            <S.SupportiveTitle>
              {allPrismicCareers.secondary_title.text}
            </S.SupportiveTitle>
          )}
          <S.SupportiveWrapper>
            {allPrismicCareers.secondary_items?.map((item, index) => (
              <S.SupportiveItem key={`supportive-item-${index}`}>
                <S.SupportiveSectionTitle>
                  {item.item_title.text}
                </S.SupportiveSectionTitle>
                <S.SupportiveSectionDescription>
                  {item.item_description.text}
                </S.SupportiveSectionDescription>
              </S.SupportiveItem>
            ))}
          </S.SupportiveWrapper>
        </DiagonalBox>
        <JobList
          jobs={jobs}
          title={allPrismicCareers.job_list_title?.text}
          description={allPrismicCareers.job_list_description?.text}
        />
      </S.CareersComponent>
    </Layout>
  )
}

export default Careers
