import styled from 'styled-components'

import { Breakpoints, Sizes } from '@/styles'
import { Width } from '@/components'

export const JobDetailPageComponent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const GreenhouseApp = styled.div`
  min-height: 80vh;
  margin-bottom: 120px;
`

export const StyledWidth = styled(Width)`
  @media (min-width: ${Breakpoints.tablet}) {
    padding: 0 ${Sizes.xPaddingTablet};
    max-width: calc(850px + ${Sizes.xPaddingTablet} + ${Sizes.xPaddingMobile});
  }
`
