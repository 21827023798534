import styled from 'styled-components'
import { animated } from 'react-spring'
import { Type, Colors } from '@/styles'

export const FoldingtextComponent = styled.div`
  display: block;
`

export const Accordion = styled(animated.div)`
  overflow: hidden;
  position: relative;
  padding-bottom: 60px;
  margin-bottom: 53px;

  &:after {
    content: '';
    height: 60px;
    width: 100%;
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 100%
    );
    position: absolute;
    bottom: 0;
  }
`

export const Content = styled.div`
  ${Type.body40};
  margin-bottom: 0;
  color: ${Colors.navy};
  margin-top: 20px;
  p:not(:last-of-type) {
    margin-bottom: 20px;
  }
`

export const TranscriptButton = styled.button`
  display: inline-block;
  padding: 12px 30px;
  background: transparent;
  border: 2px solid ${Colors.navy};
  border-radius: 40px;
  font-size: 16px;
  font-family: ${Type.fontFamily};
  font-weight: ${Type.fontWeights.regular};
  color: ${Colors.navy};
  margin: ${props => props.margin || '0'};
  transition: all 0.15s ease-in-out;
  &:hover {
    color: ${Colors.white};
    background: ${Colors.navy};
    cursor: pointer;
  }
`
