import React from 'react'
import { Link } from '@/components/'
import useStore from '@/store/'
import locales from '../../../config/i18n'

export default ({ to, ...props }) => {
  const locale = useStore(state => state.locale)

  const isIndex = to === '/'
  const path = locales[locale].isDefault
    ? to
    : `/${locales[locale].path}${isIndex ? '/' : `${to}`}`

  return <Link {...props} to={path} />
}
