import React from 'react'
import styled from 'styled-components'
import { Width } from '@/components'
import { Breakpoints, Sizes, Colors } from '@/styles'

const StyledDiagonalBox = styled.div`
  /* 
    All these calculations below are just to calculate the skew-padding to apply in each section. 
    There is nothing here you should need to change.
    The tangent is determined by the value of the --angle.
    Exact formula is:
    x = tan(deg)/2
    */
  --width: 100vw;
  --padding: 40px;
  @media (min-width: ${Breakpoints.tablet}) {
    --padding: 60px;
    --width: calc(
      ${Sizes.maxWidthTablet} + ${Sizes.xPaddingTablet} +
        ${Sizes.xPaddingMobile}
    );
  }
  @media (min-width: ${Breakpoints.desktop}) {
    --padding: 80px;
    --width: calc(
      ${Sizes.maxWidth} + ${Sizes.xPaddingMobile} + ${Sizes.xPaddingMobile}
    );
  }
  --angle: -9.85deg;
  --tangent: 0.086814415;
  --skew-padding: calc((var(--width) * var(--tangent)) + var(--padding));

  position: relative;
  padding: var(--skew-padding) 0;
  color: ${({ color }) => color ?? Colors.black};

  /* We use transform to compensate the white spacing in the section above 
  transform: translateY(calc(var(--skew-padding) * -1));
  @media (min-width: 1840px) {*/
  /* this is a hardcoded value, sorry 
    transform: translateY(calc((var(--skew-padding) * 1.46) * -1));
o  }*/

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    transform: skewy(var(--angle));
    transform-origin: 50% 0;
    outline: ${({ debug }) => (debug ? `2px dotted ${debug}` : 'none')};
    backface-visibility: hidden;
    background: ${({ bg }) => (bg ? bg : 'none')};
  }
`

const StyledContent = styled(Width)`
  margin: 0 auto;
  position: relative;
`

export const DiagonalBox = ({ children, ...rest }) => {
  return (
    <StyledDiagonalBox {...rest}>
      <StyledContent {...rest}>{children}</StyledContent>
    </StyledDiagonalBox>
  )
}
