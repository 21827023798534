import React from 'react'

const HeroArrow = props => {
  return (
    <svg
      width="52"
      height="165"
      viewBox="0 0 52 165"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M50.7483 141.201L28.4745 163.475C27.1076 164.842 24.8915 164.842 23.5247 163.475L1.25084 141.201C-0.115993 139.834 -0.115993 137.618 1.25084 136.251C2.61768 134.884 4.83376 134.884 6.20059 136.251L22.4996 152.55L22.4996 4C22.4996 2.067 24.0666 0.500001 25.9996 0.500001C27.9326 0.500001 29.4996 2.067 29.4996 4L29.4996 152.55L45.7986 136.251C47.1654 134.884 49.3815 134.884 50.7483 136.251C52.1152 137.618 52.1152 139.834 50.7483 141.201Z"
        fill="#313C49"
      />
    </svg>
  )
}

export default HeroArrow
