import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Helmet } from 'react-helmet'
import '../styles/FontFaces.css'
import { GlobalStyle } from '@/components'
import Footer from './Footer/Footer'
import { DiagonalFooter, Cookies } from './index'
import useStore from '@/store/'
import locales from '../../config/i18n'

const Wrap = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`

const Main = styled.main`
  margin-bottom: auto;
`

const Layout = ({ children, locale }) => {
  const language = useStore(state => state.locale)
  const switchLocale = useStore(state => state.switchLocale)

  useEffect(() => {
    if (locale && locale !== language) {
      switchLocale(locale)
    }
  }, [locale, language, switchLocale])

  return (
    <Wrap>
      <Helmet
        htmlAttributes={{
          lang: locales[locale].path || 'en',
        }}
      />
      {/* <Header siteTitle={data.site.siteMetadata.title} /> */}
      <GlobalStyle />
      <Main>{children}</Main>
      <Cookies />
      <Footer />
    </Wrap>
  )
}

export const LayoutRefactored = ({ children, locale }) => {
  const language = useStore(state => state.locale)
  const switchLocale = useStore(state => state.switchLocale)

  useEffect(() => {
    if (locale && locale !== language) {
      switchLocale(locale)
    }
  }, [locale, language, switchLocale])

  return (
    <Wrap>
      <Helmet
        htmlAttributes={{
          lang: locales[locale].path || 'en',
        }}
      />
      <GlobalStyle />
      <Main>{children}</Main>
      <Cookies />
      <DiagonalFooter />
    </Wrap>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
