import React from 'react'
import styled from 'styled-components'

import { Colors, Type, Breakpoints, Sizes } from '@/styles'
import { Width } from '@/components'
import LocalizedLink from '../LocalizedLink/LocalizedLink'
import Socials from '../Socials/Socials'
import { Logo, Colors as ATColors } from '@all-turtles/components'

export const Footer = styled.footer`
  /* 
    All these calculations below are just to calculate the skew-padding to apply in each section. 
    There is nothing here you should need to change.
    The tangent is determined by the value of the --angle.
    Exact formula is:
    x = tan(deg)/2
    */
  --width: 100vw;
  --full-width: 100vw;
  @media (min-width: ${Breakpoints.tablet}) {
    --width: calc(
      ${Sizes.maxWidthTablet} + ${Sizes.xPaddingTablet} +
        ${Sizes.xPaddingMobile}
    );
  }
  @media (min-width: ${Breakpoints.desktop}) {
    --width: calc(
      ${Sizes.maxWidth} + ${Sizes.xPaddingMobile} + ${Sizes.xPaddingMobile}
    );
  }
  --angle: -9.85deg;
  --tangent: 0.086814415;
  --skew-padding: calc((var(--width) * var(--tangent)) + 60px);
  --clip-padding: calc(var(--full-width) * var(--tangent));
  position: relative;
  margin-top: calc((var(--clip-padding) * -1) - 3px);
  background: ${({ bg }) => (bg ? bg : Colors.navy)};
  padding: 4em 0 4em;
  clip-path: polygon(
    0% calc(var(--clip-padding) * 2),
    100% 0%,
    100% 100%,
    0% 100%
  );

  @media (min-width: ${Breakpoints.tablet}) {
    padding: calc((var(--clip-padding) * 2) - (var(--clip-padding))) 0 4em;
  }
`

/* --------------------
Content
-------------------- */

export const StyledWidth = styled(Width)`
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 2;
`

export const Copy = styled.div``

export const CopyAndSocials = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  @media (min-width: ${Breakpoints.desktop}) {
    flex-direction: row;
  }
`

export const Partner = styled.p`
  ${Type.subhead20};
  color: ${Colors.white};
  margin-bottom: 8px;
`

export const Description = styled.p`
  ${Type.body10};
  color: ${Colors.white};
  max-width: 295px;
  margin-bottom: 24px;
  padding-right: 55px;

  @media (min-width: ${Breakpoints.tablet}) {
    max-width: 444px;
  }

  a {
    color: ${Colors.white};
    opacity: 1;
    transition: all 200ms ease;
    border-bottom: 1px solid ${Colors.white};

    &:hover {
      color: ${Colors.white};
      opacity: 0.75;
    }
  }
`

export const CopyrightAndLegal = styled.div`
  display: flex;
  flex-direction: column-reverse;

  @media (min-width: ${Breakpoints.tablet}) {
    flex-direction: row;
  }
`

export const StyledCopyright = styled.p`
  ${Type.body40};
  color: ${Colors.white};
  opacity: 0.75;
  margin-top: 12px;

  @media (min-width: ${Breakpoints.tablet}) {
    margin-top: 0;
  }
`

export const StyledLink = styled(props => <LocalizedLink {...props} />)`
  ${Type.body40};
  color: ${Colors.white};
  opacity: 1;
  transition: all 200ms ease;
  margin-left: 12px;
  border-bottom: 1px solid ${Colors.white};

  &:first-child {
    margin-left: 0;
  }

  @media (min-width: ${Breakpoints.tablet}) {
    &:first-child {
      margin-left: 12px;
    }
  }

  &:hover {
    color: ${Colors.white};
    opacity: 0.75;
  }
`

export const StyledLogo = styled(Logo)`
  margin-bottom: 37px;
  width: 44px;
  height: 33px;
  align-self: flex-end;
  flex-shrink: 0;

  @media (min-width: ${Breakpoints.tablet}) {
    margin-bottom: 60px;
  }

  @media (min-width: ${Breakpoints.desktop}) {
    margin-bottom: 131px;
    width: 68px;
    height: 52px;
  }
`

export const DesktopSocials = styled(Socials)`
  display: none;

  @media (min-width: ${Breakpoints.desktop}) {
    display: flex;
  }
`

export const MobileSocials = styled(Socials)`
  display: flex;

  @media (min-width: ${Breakpoints.desktop}) {
    display: none;
  }
`

export const MailTo = styled.a`
  color: ${Colors.white};
  border-bottom: 1px solid currentColor;

  &:hover {
    color: ${ATColors.brand};
  }
`
