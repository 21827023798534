import React from 'react'

import * as S from './styles'

const NextCaseStudy = ({ url, children }) => {
  return (
    <S.NextCaseStudyComponent>
      <S.StyledWidth>
        <S.StyledLink to={url}>
          {children}
          <S.StyledArrow arrowProps={{ role: 'none' }} />
        </S.StyledLink>
      </S.StyledWidth>
    </S.NextCaseStudyComponent>
  )
}

export default NextCaseStudy
