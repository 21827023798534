import React from 'react'
import { Colors } from '@/styles'
import * as S from './styles'

const Banner = ({ text, slug }) => {
  return (
    <S.BannerComponent>
      <S.StyledLink to={slug}>
        <span role="img" aria-label="celebration popper">
          🎉
        </span>{' '}
        {text}{' '}
        <S.StyledArrow color={Colors.white} arrowProps={{ role: 'none' }} />
      </S.StyledLink>
    </S.BannerComponent>
  )
}

export default Banner
