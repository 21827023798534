import React from 'react'

import * as S from './styles'

const Job = ({ title, url, ...rest }) => {
  return (
    <S.JobComponent {...rest}>
      <S.StyledLink to={url}>
        <S.TitleWrap>
          <S.Title>
            {title}
            <S.StyledArrow arrowProps={{ role: 'none' }} />
          </S.Title>
        </S.TitleWrap>
      </S.StyledLink>
    </S.JobComponent>
  )
}

export default Job
