import React from 'react'
import styled from 'styled-components'

import { Type } from '@/styles'
import LocalizedLink from '../LocalizedLink/LocalizedLink'
import HoverArrow from '@/components/HoverArrow/HoverArrow'

export const JobComponent = styled.div``

export const TitleWrap = styled.div`
  display: flex;
`

export const Title = styled.h3`
  ${Type.header4};
`

export const StyledArrow = styled(HoverArrow)`
  margin-left: 8px;
  transform: scale(0.7);
  position: relative;
  display: inline-block;
  height: 20px;

  .arrow {
    left: -35px;
  }
`

export const StyledLink = styled(props => <LocalizedLink {...props} />)`
  display: block;

  &:hover {
    ${StyledArrow} {
      .arrow {
        left: 0px;
      }
    }
  }
`
