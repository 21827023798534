import React from 'react'
import styled from 'styled-components'
import Image from 'gatsby-image'

import { Breakpoints, Sizes, Type, Colors } from '@/styles'
import { Link, LocalizedLink } from '@/components/index'
import Width from '../Width/Width'
import ShortArrow from '@/components/Icons/ShortArrow'

export const CaseStudyLayoutComponent = styled.main`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const Header = styled.div`
  box-sizing: border-box;

  position: relative;

  display: flex;
  justify-content: center;

  width: 100vw;
  height: auto;

  padding-top: calc(${Sizes.navHeight} + 120px);
  padding-bottom: 60px;

  @media (min-width: ${Breakpoints.desktop}) {
    padding-bottom: 120px;
  }

  &:before {
    display: block;
    content: '';
    width: 100vw;
    height: 710px;
    position: absolute;
    top: -999px;
    left: 0;

    height: calc(100% + 999px);
    width: 100%;

    background-color: ${props => props.color};
    transform: skewY(-9.85deg);
  }
`

export const Media = styled.div`
  margin-top: -60px;

  @media (min-width: ${Breakpoints.desktop}) {
    margin-top: -120px;
  }
`

export const StyledImage = styled(Image)`
  width: 100%;
  margin-bottom: 26px;

  @media (min-width: ${Breakpoints.desktop}) {
    margin-bottom: 112px;
  }
`

export const BackLink = styled(props => <LocalizedLink {...props} />)`
  height: 27px;
  display: flex;
  align-items: center;
  z-index: 2;

  @media (min-width: ${Breakpoints.tablet}) {
    height: 54px;
  }
`

export const BackWrap = styled.div`
  width: 100vw;
  display: flex;
  justify-content: center;
  position: absolute;
  left: 0;
`

export const BackMaxWidth = styled.div`
  width: 100%;
  max-width: 1320px;
  padding: 0 20px;
  display: flex;
  justify-content: flex-start;
`

export const BackButton = styled(ShortArrow)`
  transform: scaleX(-1);
  width: 21px;

  path {
    fill: ${props => props.color};
  }

  @media (min-width: ${Breakpoints.desktop}) {
    width: auto;
  }
`

export const TitleAndTags = styled.div``

export const Title = styled.h1`
  ${Type.header2};
  color: ${props => props.color};
  margin-bottom: 10px;

  @media (min-width: ${Breakpoints.desktop}) {
    margin-bottom: 35px;
  }
`

export const Tags = styled.p`
  ${Type.body40};
  color: ${props => props.color};
  margin-bottom: 70px;

  @media (min-width: ${Breakpoints.desktop}) {
    margin-bottom: 0;
  }
`

export const LongDescription = styled.p`
  ${Type.body20};
  color: ${props => props.color};

  padding-top: 0;

  @media (min-width: ${Breakpoints.tablet}) {
    max-width: 254px;
    margin-left: 40px;
    ${props => (props.header ? 'padding-top: 58px' : '')};
  }

  @media (min-width: ${Breakpoints.desktop}) {
    max-width: 50%;
    ${props => (props.header ? 'padding-top: 110px' : '')};
  }
`

export const MaxWidth = styled(Width)`
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 39px;

  @media (min-width: ${Breakpoints.tablet}) {
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 78px;
  }
`

export const StyledWidth = styled(Width)`
  position: relative;
  z-index: 2;
`

export const SecondaryDescriptionMaxWidth = styled(Width)`
  display: flex;

  flex-direction: column;
  margin-bottom: 60px;

  @media (min-width: ${Breakpoints.tablet}) {
    align-items: flex-end;
  }
`

export const Content = styled.div`
  position: relative;
  z-index: 2;
`

export const Url = styled(Link)`
  font-weight: bold;
  font-size: 30px;
  line-height: 35px;
  color: ${props => props.color};

  margin-top: 30px;
  @media (min-width: ${Breakpoints.desktop}) {
    margin-top: 60px;
  }

  &:hover {
    svg path {
      fill: ${Colors.text};
    }
  }
`

export const Arrow = styled.svg`
  position: relative;
  top: 6px;
  margin-left: 15px;
  path {
    fill: ${props => props.color};
  }
`

export const ResponsiveVideoContainer = styled.div`
  width: 100%;
  height: auto;
  margin-bottom: 26px;

  & > div {
    padding-top: calc(
      ${props => props.height ?? 574} / ${props => props.width ?? 1020} * 100%
    );
    /* height / width * 100% = calculate aspect ratio */
  }

  @media (min-width: ${Breakpoints.desktop}) {
    margin-bottom: 112px;
  }
`
