import React from 'react'
import * as S from './styles'
import { Navigation } from '@/components'

const CaseStudyHeader = props => {
  const { isAboutATPage } = props
  return (
    <S.CaseStudyHeaderComponent>
      <S.StyledWidth>
        <Navigation isAboutATPage={isAboutATPage} />
      </S.StyledWidth>
    </S.CaseStudyHeaderComponent>
  )
}

export default CaseStudyHeader
