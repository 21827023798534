import { css, createGlobalStyle } from 'styled-components'
import 'focus-visible'

import * as Breakpoints from './Breakpoints'
import * as Colors from './Colors'

const sansSerifFallback =
  "'-apple-system', 'BlinkMacSystemFont', 'Helvetica Neue', 'Roboto', 'sans-serif'"
export const fontFamily = `'Quasimoda', ${sansSerifFallback}`

export const fontWeights = {
  light: 300,
  regular: 400,
  bold: 700,
}

export const body = css`
  font-size: 14px;

  @media (min-width: ${Breakpoints.tablet}) {
    font-size: 16px;
  }
`

export const body10 = css`
  font-size: 22px;
  line-height: 26px;
  font-weight: ${fontWeights.light};

  @media (min-width: ${Breakpoints.tablet}) {
    font-size: 27px;
    line-height: 32px;
  }

  @media (min-width: ${Breakpoints.desktop}) {
    font-size: 30px;
    line-height: 36px;
  }
`

export const body20 = css`
  font-size: 20px;
  line-height: 26px;

  @media (min-width: ${Breakpoints.desktop}) {
    font-size: 25px;
    line-height: 33px;
  }
`

export const body30 = css`
  font-size: 17px;
  line-height: 25px;

  @media (min-width: ${Breakpoints.tablet}) {
    font-size: 18px;
    line-height: 25px;
  }

  @media (min-width: ${Breakpoints.desktop}) {
    font-size: 20px;
    line-height: 25px;
  }
`

export const body40 = css`
  font-size: 15px;
  line-height: 25px;
`

export const subhead20 = css`
  font-size: 12px;
  line-height: 100%;
  font-weight: ${fontWeights.bold};
  text-transform: uppercase;
  letter-spacing: 0.1em;

  @media (min-width: ${Breakpoints.tablet}) {
    font-size: 13px;
  }

  @media (min-width: ${Breakpoints.desktop}) {
    font-size: 15px;
  }
`

export const header1 = css`
  font-size: 40px;
  line-height: 100%;
  font-weight: ${fontWeights.bold};

  @media (min-width: ${Breakpoints.tablet}) {
    font-size: 65px;
    line-height: 100%;
  }

  @media (min-width: ${Breakpoints.desktop}) {
    font-size: 75px;
    line-height: 100%;
  }
`

export const header2 = css`
  font-size: 30px;
  line-height: 35px;
  font-weight: ${fontWeights.bold};
  margin: 0;

  @media (min-width: ${Breakpoints.tablet}) {
    font-size: 40px;
    line-height: 48px;
  }

  @media (min-width: ${Breakpoints.desktop}) {
    font-size: 60px;
    line-height: 72px;
  }
`

export const header3 = css`
  font-size: 30px;
  line-height: 100%;
  font-weight: ${fontWeights.bold};
  margin: 0;

  @media (min-width: ${Breakpoints.tablet}) {
    font-size: 35px;
    line-height: 42px;
  }

  @media (min-width: ${Breakpoints.desktop}) {
    font-size: 45px;
    line-height: 54px;
  }
`

export const header4 = css`
  font-size: 20px;
  line-height: 138%;
  font-weight: ${fontWeights.bold};
  margin: 0;

  @media (min-width: ${Breakpoints.tablet}) {
    font-size: 30px;
    line-height: 1.16;
  }
`

export const capsTitle = css`
  font-weight: ${fontWeights.bold};
  font-size: 14px;
  letter-spacing: 0.14rem;
  text-transform: uppercase;

  @media (min-width: ${Breakpoints.tablet}) {
    font-size: 17px;
    letter-spacing: 0.18rem;
  }
`

export const navItem = css`
  font-weight: ${fontWeights.bold};
  font-size: 15px;
  line-height: 1.66;
  letter-spacing: 0.1em;
  text-transform: uppercase;
`

export const GlobalType = createGlobalStyle`
  body {
    ${body};
    font-family: ${fontFamily};
  }

  h1 {
    ${header1};
  }

  h2 {
    ${header2};
  }

  h3 {
    ${header3};
  }

  h4 {
    ${header4};
  }

  p {
    margin: 0;
  }

  a {
    transition: all 300ms ease;
    color: ${Colors.text};
    cursor: pointer;
    text-decoration: none;

    &:hover {
      color: ${Colors.text};
    }

    &:focus {
      outline: none;
      box-shadow: 0 0 0 2px #EB4646;
      border-radius: 4px;
    }

    &:focus:not(.focus-visible) {
      outline: none;
      box-shadow: none;
    }
  }
`
