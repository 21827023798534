import React from 'react'
import { Link } from '@/components'
import {
  ApplePodcast,
  Soundcloud,
  Stitcher,
  Overcast,
  Spotify,
  GooglePlay,
  RssFeed,
  IHeartRadio,
} from '@/components/Icons/index'
import * as S from './styles'

const PodcastSourcesHeader = props => {
  return (
    <S.PodcastSourcesHeaderComponent>
      <S.List>
        <S.ListItem>
          <Link
            el="a"
            to="https://podcasts.apple.com/us/podcast/the-all-turtles-podcast/id1304493828"
          >
            <ApplePodcast role="presentation" />
          </Link>
        </S.ListItem>
        <S.ListItem>
          <Link el="a" to="https://soundcloud.com/allturtlespodcast">
            <Soundcloud role="presentation" />
          </Link>
        </S.ListItem>
        <S.ListItem>
          <Link
            el="a"
            to="https://www.stitcher.com/podcast/all-turtles/the-all-turtles-podcast"
          >
            <Stitcher role="presentation" />
          </Link>
        </S.ListItem>
        <S.ListItem>
          <Link
            el="a"
            to="https://overcast.fm/itunes1304493828/the-all-turtles-podcast"
          >
            <Overcast role="presentation" />
          </Link>
        </S.ListItem>
        <S.ListItem>
          <Link
            el="a"
            to="https://open.spotify.com/show/1ajqipnGXHqKBaZqRiWIAe"
          >
            <Spotify role="presentation" />
          </Link>
        </S.ListItem>
        <S.ListItem>
          <Link
            el="a"
            to="https://podcasts.google.com/feed/aHR0cHM6Ly9hbGx0dXJ0bGVzLmxpYnN5bi5jb20vcnNz"
          >
            <GooglePlay role="presentation" />
          </Link>
        </S.ListItem>
        <S.ListItem>
          <Link el="a" to="http://feeds.feedburner.com/TheAllTurtlesPodcast">
            <RssFeed role="presentation" />
          </Link>
        </S.ListItem>
        <S.ListItem>
          <Link
            el="a"
            to="https://www.iheart.com/podcast/263-the-all-turtles-pod-29037900/"
          >
            <IHeartRadio role="presentation" />
          </Link>
        </S.ListItem>
      </S.List>
    </S.PodcastSourcesHeaderComponent>
  )
}

export default PodcastSourcesHeader
