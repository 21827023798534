import React, { useState, useEffect } from 'react'
import { useSpring } from 'react-spring'
import { useMeasure } from 'react-use'

import * as S from './styles'

const Foldingtext = ({
  html = 'Dolor consectetur minim consectetur aliqua cillum.',
  expandText = 'View full transcript',
  closetext = 'Close full transcript',
}) => {
  const defaultHeight = '200px'
  const [open, toggle] = useState(false)
  const [contentHeight, setContentHeight] = useState(defaultHeight)
  const [ref, { height }] = useMeasure()

  // Animations
  const expand = useSpring({
    config: { friction: 10 },
    height: open ? `${contentHeight}px` : defaultHeight,
  })

  useEffect(() => {
    setContentHeight(height)
    window.addEventListener('resize', setContentHeight(height))
    return window.removeEventListener('resize', setContentHeight(height))
  }, [height])

  return (
    <S.FoldingtextComponent>
      <S.Accordion
        style={expand}
        role="region"
        id="transcript"
        aria-labelledby="episode-transcript"
      >
        <S.Content
          ref={ref}
          dangerouslySetInnerHTML={{
            __html: html,
          }}
        />
      </S.Accordion>
      <S.TranscriptButton
        role="button"
        id="episode-transcript"
        aria-expanded={open}
        aria-controls="transcript"
        onClick={() => toggle(!open)}
        onKeyDown={e =>
          e.key === 'enter' || e.key === 'space' ? toggle(!open) : null
        }
      >
        {!open ? expandText : closetext}
      </S.TranscriptButton>
    </S.FoldingtextComponent>
  )
}

export default Foldingtext
