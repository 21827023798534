import React from 'react'
import * as S from './styles'
import { LayoutRefactored } from '@/components/layout'
import {
  PodcastEpisodeHeader,
  PodcastEpisodeSidebar,
  SEO,
  DiagonalBox,
} from '@/components'

const PodcastTranscriptPage = ({ data, pageContext }) => {
  if (!data) return null
  const {
    title,
    transcript,
    summary,
    episode,
    social_image,
  } = data.prismicPodcastepisode.data

  const episodes = data.allPrismicPodcastepisode
    ? data.allPrismicPodcastepisode.edges
    : []

  return (
    <>
      <LayoutRefactored locale={pageContext.lang}>
        <S.PodcastTranscriptPageComponent>
          <SEO
            title={`Culture Fit | ${episode}. ${title.text} | Transcript`}
            description={`Culture Fit | Episode ${episode} – "${title.text}" | Transcript`}
            image={social_image.url}
          />{' '}
          {/* Pass episode header data/props to component below. */}
          <PodcastEpisodeHeader
            title={title}
            episode={episode}
            summary={summary}
          />
          <DiagonalBox bg="white">
            <S.ContentWrapper>
              <S.ContentMain>
                <S.Title>Show notes</S.Title>
                {/* Replace dummy text with the transcript text or innerHTML. */}
                {transcript.html && (
                  <S.Transcript
                    dangerouslySetInnerHTML={{
                      __html: transcript.html,
                    }}
                  />
                )}
              </S.ContentMain>
              <S.ContentSidebar>
                {/* Pass episode sidebar data/props to component below. */}
                <PodcastEpisodeSidebar episodes={episodes} />
              </S.ContentSidebar>
            </S.ContentWrapper>
          </DiagonalBox>
        </S.PodcastTranscriptPageComponent>
      </LayoutRefactored>
    </>
  )
}

export default PodcastTranscriptPage
