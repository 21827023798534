import React from 'react'

const Facebook = props => {
  return (
    <svg
      width="52"
      height="52"
      viewBox="0 0 52 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-label="Facebook"
      {...props}
    >
      <title>Facebook</title>
      <g clipPath="url(#clipFacebook)" fill="#fff">
        <path d="M25.52 51.05a25.53 25.53 0 110-51.06 25.53 25.53 0 010 51.06zm0-48.31a22.78 22.78 0 100 45.56 22.78 22.78 0 000-45.56z" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21.5 25.64v14.8a.38.38 0 00.4.38h5.5a.38.38 0 00.38-.38V25.4h4.01a.38.38 0 00.39-.35l.38-4.53a.38.38 0 00-.38-.41h-4.4v-3.22a1.36 1.36 0 011.37-1.37h3.07a.38.38 0 00.38-.38v-4.53a.38.38 0 00-.38-.39h-5.19a5.52 5.52 0 00-5.51 5.52v4.37h-2.74a.38.38 0 00-.4.38V25a.38.38 0 00.4.39h2.73v.24z"
        />
      </g>
      <defs>
        <clipPath>
          <path fill="#fff" d="M0 0h51.05v51.05H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default Facebook
