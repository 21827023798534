import React from 'react'

import * as S from './styles'
import { Width } from '@/components'

const CaseStudyCard = props => {
  const {
    home_image,
    title,
    home_description,
    primary_color,
    text_color,
    url,
  } = props

  const to = `/case-studies/${url}`

  return (
    <S.CaseStudyCardComponent>
      <S.Rectangle color={primary_color} />
      <S.RectMask>
        <S.OpacityRectangle color={primary_color} />
      </S.RectMask>
      <S.ClipImage>
        <S.StyledWidth>
          {home_image?.localFile?.childImageSharp?.fluid && (
            <S.StyledImage
              fluid={home_image.localFile.childImageSharp.fluid}
              role="none"
              alt=""
            />
          )}
          <S.Text>
            {/** TODO fix a11y on the way this group is read */}
            <S.StyledLink to={to}>
              <S.TextWrap>
                {title.text && (
                  <S.Title fontColor={text_color}>{title.text}</S.Title>
                )}
                {home_description.text && (
                  <S.Description fontColor={text_color}>
                    {home_description.text}
                  </S.Description>
                )}
                <S.StyledArrow
                  color={text_color}
                  arrowProps={{ role: 'none' }}
                />
              </S.TextWrap>
            </S.StyledLink>
          </S.Text>
        </S.StyledWidth>
      </S.ClipImage>
      <S.TextMobile>
        <Width>
          <S.StyledLink to={to}>
            <S.TextWrap>
              {title.text && (
                <S.Title fontColor={text_color}>{title.text}</S.Title>
              )}
              {home_description.text && (
                <S.Description fontColor={text_color}>
                  {home_description.text}
                </S.Description>
              )}
              <S.StyledArrow color={text_color} />
            </S.TextWrap>
          </S.StyledLink>
        </Width>
      </S.TextMobile>
    </S.CaseStudyCardComponent>
  )
}

export default CaseStudyCard
