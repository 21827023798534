export const text = '#313C49'
export const white = '#fff'
export const gray = '#EAEAEA'
export const lightGray = '#F1F1F1'
export const offWhite = '#f2f2f2'
export const navy = '#2E3C4A'
export const selectionRed = '#D13E3E'
export const blue = '#2F79CB'
export const red = '#EB4646'
export const lightBlue = '#CFDCE3'

// Project colors
export const carrot = '#F0E7BC'
export const vitally = '#3F6C72'
export const tellus = '#7C92C4'
export const disco = '#3383A9'
export const spot = '#3B3363'
export const replika = '#EC8179'
export const salty = '#CFCFCF'
export const sift = '#3D496E'
export const sunflower = '#D8505A'
export const humanCap = '#BB945B'
export const mmhmm = '#4235F2'
