import React from 'react'

import * as S from './styles'
import Layout from '@/components/layout'
import { CaseStudyHeader, Width, SEO } from '@/components'

const NotFound = props => {
  const notFoundData = props.data.prismic404.data

  return (
    <Layout locale={props.pageContext.lang}>
      <S.NotFoundComponent>
        {notFoundData?.seo_title?.text && (
          <SEO title={notFoundData.seo_title.text} />
        )}
        <CaseStudyHeader />
        <Width>
          {notFoundData?.title?.text && <h1>{notFoundData.title.text}</h1>}
          {notFoundData?.description?.text && (
            <p>{notFoundData.description.text}</p>
          )}
          <S.StyledLink to="/">
            ← {notFoundData?.back_home_link_text?.text ?? ''}
          </S.StyledLink>
        </Width>
      </S.NotFoundComponent>
    </Layout>
  )
}

export default NotFound
