import React from 'react'

import * as S from './styles'
import { CaseStudyHeader } from '@/components'

const TextPage = ({ title, children }) => {
  return (
    <S.PrivacyPolicyComponent>
      <CaseStudyHeader />
      <S.Header>
        <S.Width>
          <S.H1>{title}</S.H1>
        </S.Width>
      </S.Header>
      <S.Width>{children}</S.Width>
    </S.PrivacyPolicyComponent>
  )
}

export default TextPage
