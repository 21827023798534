import styled from 'styled-components'

import { Colors, Type, Breakpoints, Sizes } from '@/styles'
import { Privacy, Colors as AtColors } from '@all-turtles/components'

export const PrivacyPolicyComponent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;

  .at-date {
    ${Type.body40};
  }

  p {
    ${Type.body30};
    margin-bottom: 2rem;
  }

  ul {
    ${Type.body30};
  }

  h4 {
    margin: 3rem 0 0 0;
    font-size: 30px;
    line-height: 35px;
  }

  h5 {
    ${Type.subhead20};
    margin-top: 3rem;
    margin-bottom: 4px;
  }

  a {
    text-decoration: underline;

    &:hover {
      text-decoration-color: ${AtColors.brand};
    }
  }

  @media (max-width: ${Breakpoints.tablet}) {
    padding-bottom: calc(${Sizes.navHeight} + 72px);
  }
`

export const StyledPrivacy = styled(Privacy)``

export const Header = styled.div`
  padding-top: calc(${Sizes.navHeight});
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding-bottom: 34px;
  width: 100vw;
  height: auto;
  box-sizing: border-box;
  position: relative;
  margin-bottom: 46px;
  height: 282px;
  background-color: ${Colors.gray};

  @media (min-width: ${Breakpoints.tablet}) {
    padding-top: calc(${Sizes.navHeight} + 72px);
  }
`

export const H1 = styled.h1`
  ${Type.header3};
  color: ${Colors.navy};
  position: relative;
  z-index: 2;
`

export const Width = styled.div`
  position: relative;
  z-index: 2;
  width: 100%;
  max-width: ${Sizes.maxWidthMobile};
  padding: 0 ${Sizes.xPaddingMobile};
  box-sizing: border-box;

  @media (min-width: ${Breakpoints.tablet}) {
    padding: 0 ${Sizes.xPaddingTablet};
    max-width: calc(674px + ${Sizes.xPaddingTablet} + ${Sizes.xPaddingMobile});
  }

  @media (min-width: ${Breakpoints.desktop}) {
    max-width: calc(674px + ${Sizes.xPaddingMobile} + ${Sizes.xPaddingMobile});
  }
`
