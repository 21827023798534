import styled, { css } from 'styled-components'
import { Breakpoints, Sizes, Type, Colors } from '@/styles'

export const Hero = styled.div`
  padding-top: calc(${Sizes.navHeight} + 130px);
  @media (min-width: ${Breakpoints.tablet}) {
    padding-top: calc(${Sizes.navHeight} + 197px);
  }

  > div {
    padding-bottom: 80px;
  }

  @media (min-width: ${Breakpoints.desktop}) {
    > div {
      padding-bottom: 120px;
    }
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    bottom: 0;
    right: 0;
    background: ${({ bg }) => (bg ? bg : Colors.gray)};
  }
`

/* --------------------
Typography
-------------------- */

const HeroText = css`
  ${Type.body20};
  color: ${({ color }) => (color ? color : Colors.text)};
  margin-bottom: 10px;
  @media (min-width: ${Breakpoints.desktop}) {
    margin-bottom: 10px;
    max-width: 674px;
  }
`

export const Title = styled.h1`
  ${Type.header4};
  color: ${({ color }) => (color ? color : Colors.text)};
  margin-bottom: 10px;
  @media (min-width: ${Breakpoints.desktop}) {
    margin-bottom: 35px;
    max-width: 674px;
  }
`

export const Subhead = styled.p`
  ${Type.subhead20};
  color: ${({ color }) => (color ? color : Colors.text)};
  margin-bottom: 10px;
  a {
    color: ${Colors.white};
    :hover {
      color: ${Colors.red};
    }
  }
  @media (min-width: ${Breakpoints.desktop}) {
    margin-bottom: 10px;
  }
`

export const Text = styled.p`
  ${HeroText};
`

export const Desc = styled.div`
  p {
    ${HeroText};
  }
`
