import styled from 'styled-components'
import { Type } from '@/styles'
import Link from '@/components/Link/Link'

export const PodcastSourcesListComponent = styled.div`
  margin-top: 20px;
`

export const List = styled.ul`
  flex: 1;
  list-style: none;
  margin: 0;
  padding: 0;
`

export const ListItem = styled.li`
  transform: translateX(0px);
  transition: all 350ms ease-in;
  transform-origin: 0% 50%;
  cursor: pointer;
  svg {
    margin-right: 10px;
    height: 27px;
    width: 27px;
  }
  + li {
    margin-top: 10px;
  }
  &:hover {
    transform: translateX(10px);
  }
`

export const StyledLink = styled(Link)`
  ${Type.body40};
  display: flex;
  align-items: center;
`
