import React from 'react'

const Footer = props => {
  return (
    <svg
      {...props}
      xmlns='http://www.w3.org/2000/svg'
      x='0px'
      y='0px'
      viewBox='0 0 2560 700'
    >
      <polygon fill='#2E3C4A' points='2560,1.2 0,445.9 0,700 2560,700 ' />
    </svg>
  )
}

export default Footer
