import React from 'react'
import styled from 'styled-components'

import { LocalizedLink } from '@/components'
import { Type } from '@/styles'
import { Colors } from '@all-turtles/components'

export const CareersLink = styled(props => <LocalizedLink {...props} />)`
  ${Type.subhead20};
  transform: color 200ms ease;

  &:hover {
    color: ${Colors.brand};
  }
`
