import React from 'react'
import * as S from './styles'
import {
  TextPage,
  AllTurtlesLogo,
  AllTurtlesMonogram,
  TwistyTongueLogo,
  TwistyTongueMonogram,
} from '@/components'

/** Maybe NEED i18n ?? */
const Trademarks = () => {
  return (
    <TextPage title="Trademarks">
      <S.TrademarksContent>
        <p>
          This is a current listing of trademarks owned by All Turtles
          Corporation and Registered in the U.S. Patent and Trademark Office.
        </p>
        <h4>Word marks</h4>
        <ul>
          <li>ALL TURTLES®</li>
          <li>SIFT®</li>
          <li>NEWS THERAPY®</li>
          <li>FEEL NEWS DIFFERENTLY®</li>
          <li>TWISTY TONGUE®</li>
        </ul>
        <h4>Logos and design marks</h4>
        <ul>
          <li>
            <AllTurtlesLogo />
          </li>
          <li>
            <AllTurtlesMonogram />
          </li>
          <li>
            <TwistyTongueLogo />
          </li>
          <li>
            <TwistyTongueMonogram />
          </li>
        </ul>
      </S.TrademarksContent>
    </TextPage>
  )
}

export default Trademarks
