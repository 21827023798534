import React from 'react'
import * as S from './styles'

const getDepartmentTemporary = (dep, title) => {
  if (dep === 'Sora Union') {
    switch (title) {
      case 'Brand Designer I for Sora Union':
      case 'Brand Designer II for Sora Union':
      case 'Design Manager for Sora Union':
        return 'Design'

      case 'Localization Specialist for Sora Union - Arabic':
      case 'Localization Team Lead for Sora Union - Arabic':
        return 'Marketing and Communications'

      default:
        return dep
    }
  }

  return dep
}

const JobList = ({ jobs, title, description }) => {
  return (
    <S.JobListWrapper>
      <div>
        {title && <S.SectionTitle>{title}</S.SectionTitle>}
        {description && (
          <S.SectionDescription>{description}</S.SectionDescription>
        )}
      </div>
      <S.StyledList lang="en">
        {jobs.map(item => {
          return (
            <S.StyledLink key={item.gh_Id} to={`/careers/${item.gh_Id}`}>
              <S.TableCell style={{ gridArea: 'company' }}>
                {item.companies}
              </S.TableCell>
              <S.TableCell style={{ gridArea: 'department' }}>
                {getDepartmentTemporary(item.departments, item.title)}
              </S.TableCell>
              <S.HighlightedTableCell style={{ gridArea: 'role' }}>
                {item.title}
              </S.HighlightedTableCell>
            </S.StyledLink>
          )
        })}
      </S.StyledList>
    </S.JobListWrapper>
  )
}

export default JobList
