import React from 'react'

import { TextPage } from '@/components'
import { Terms } from '@all-turtles/components'

/**
 *
 * Maybe NEED i18n???
 */
const TermsComponent = props => {
  return (
    <TextPage title="Terms of use">
      <Terms />
    </TextPage>
  )
}

export default TermsComponent
