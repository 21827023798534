import * as React from 'react'

function RssFeed (props) {
  return (
    <svg width={66} height={67} viewBox='0 0 66 67' fill='none' {...props}>
      <path
        d='M0 33.888c0-18.247 14.754-33 33-33 18.246 0 33 14.753 33 33 0 18.246-14.754 33-33 33-18.246 0-33-14.754-33-33z'
        fill='#F2F2F2'
      />
      <path
        d='M39.976 26.602c-5.633-5.633-13.117-8.732-21.093-8.732v5.861c6.408 0 12.424 2.489 16.945 7.01 4.521 4.52 7.01 10.537 7.01 16.945h5.87c0-7.967-3.099-15.46-8.732-21.084z'
        fill='#F78422'
      />
      <path
        d='M18.818 27.796v5.861c7.74 0 14.029 6.299 14.029 14.028h5.86c.01-10.965-8.914-19.89-19.889-19.89z'
        fill='#F78422'
      />
      <path
        d='M22.985 47.86a4.202 4.202 0 100-8.404 4.202 4.202 0 000 8.404z'
        fill='#F78422'
      />
    </svg>
  )
}

export default RssFeed
