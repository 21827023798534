import * as React from 'react'

function GooglePlay(props) {
  return (
    <svg width={66} height={67} viewBox="0 0 66 67" fill="none" {...props}>
      <path
        d="M0 33.444c0-18.246 14.754-33 33-33 18.246 0 33 14.754 33 33 0 18.247-14.754 33-33 33-18.246 0-33-14.753-33-33z"
        fill="#F2F2F2"
      />
      <path
        d="M18.592 32.572v2.587a2.37 2.37 0 11-4.742-.001V32.57a2.37 2.37 0 114.742.001z"
        fill="#0066D9"
      />
      <path
        d="M47.048 32.64l-.002-.066a2.37 2.37 0 014.741 0l-.001.066h.001v2.588h-.001a2.371 2.371 0 01-4.741 0h-.002l.005-2.588z"
        fill="#4285F4"
      />
      <path
        d="M26.783 40.331v2.588a2.37 2.37 0 01-4.741 0V40.33a2.37 2.37 0 014.74 0zm0-15.52v8.46a2.37 2.37 0 01-4.741 0v-8.46a2.37 2.37 0 014.74 0z"
        fill="#EA4335"
      />
      <path
        d="M38.853 27.4a2.37 2.37 0 004.74 0v-2.587a2.371 2.371 0 10-4.74 0v2.588z"
        fill="#34A853"
      />
      <path
        d="M30.45 19.854a2.37 2.37 0 004.74 0v-2.587a2.37 2.37 0 00-4.74 0v2.587zm0 28.023a2.37 2.37 0 014.74 0v2.587a2.37 2.37 0 01-4.74 0v-2.587z"
        fill="#FAB908"
      />
      <path
        d="M38.853 34.513a2.37 2.37 0 014.74 0v8.407a2.37 2.37 0 01-4.74 0v-8.407z"
        fill="#34A853"
      />
      <path
        d="M35.19 26.968v13.796a2.37 2.37 0 01-4.74 0V26.968a2.37 2.37 0 014.74 0z"
        fill="#FAB908"
      />
    </svg>
  )
}

export default GooglePlay
