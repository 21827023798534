import React from 'react'

import { CaseStudyLayout } from '@/components'

const Layout = props => {
  const layout = props.pageContext.frontmatter?.layout
  if (layout === 'case-study') {
    return <CaseStudyLayout {...props} />
  }

  return <>{props.children}</>
}

export default Layout
