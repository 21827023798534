import React from 'react'

import '@/styles/FontFaces.css'
import { GlobalType } from '@/styles/Type'
import { GlobalStyle } from '@/styles/Global'

const GlobalStyleComponent = props => {
  return (
    <>
      <GlobalType />
      <GlobalStyle />
    </>
  )
}

export default GlobalStyleComponent
