import React from 'react'

const Arrow = props => {
  const { title, ...rest } = props

  return (
    <svg
      width="88"
      height="30"
      viewBox="0 0 88 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      {title ? <title>{title}</title> : null}
      <path
        d="M2 13C0.895431 13 9.65645e-08 13.8954 0 15C-9.65645e-08 16.1046 0.89543 17 2 17L2 13ZM87.4142 16.4142C88.1953 15.6332 88.1953 14.3668 87.4142 13.5858L74.6863 0.857871C73.9052 0.0768219 72.6389 0.0768218 71.8579 0.85787C71.0768 1.63892 71.0768 2.90525 71.8579 3.6863L83.1716 15L71.8579 26.3137C71.0768 27.0948 71.0768 28.3611 71.8579 29.1421C72.6389 29.9232 73.9052 29.9232 74.6863 29.1421L87.4142 16.4142ZM2 17L86 17L86 13L2 13L2 17Z"
        fill="#313C49"
      />
    </svg>
  )
}

export default Arrow
