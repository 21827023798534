const website = require('./site')

/* 
    important: 
    add here all languages defined in prismic, with their exact locales
*/

module.exports = {
  'en-us': {
    isDefault: true,
    title: 'English',
    path: 'en',
    prismicLocale: 'en-us',
    rtl: false,
    defaultTitle: website.title,
    defaultDescription: website.description,
  },
  'uk-ua': {
    title: 'Українська',
    path: 'uk',
    prismicLocale: 'uk-ua',
    rtl: false,
    defaultTitle: website.title,
    defaultDescription: website.description,
  },
}
