import styled from 'styled-components'

import { Breakpoints, Sizes } from '@/styles'

const Width = styled.div`
  width: 100%;
  max-width: ${Sizes.maxWidthMobile};
  padding: 0 ${Sizes.xPaddingMobile};
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;
  position: relative;

  @media (min-width: ${Breakpoints.tablet}) {
    padding: 0 ${Sizes.xPaddingTablet};
    max-width: calc(
      ${Sizes.maxWidthTablet} + ${Sizes.xPaddingTablet} +
        ${Sizes.xPaddingMobile}
    );
  }

  @media (min-width: ${Breakpoints.desktop}) {
    max-width: calc(
      ${Sizes.maxWidth} + ${Sizes.xPaddingMobile} + ${Sizes.xPaddingMobile}
    );
  }
`

export default Width
