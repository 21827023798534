import React from 'react'
import * as S from './styles'
import { LayoutRefactored } from '@/components/layout'
import {
  PodcastSourcesList,
  CaseStudyHeader,
  SEO,
  DiagonalBox,
  Width,
  LocalizedLink,
} from '@/components'
import { Colors } from '@/styles'

const PodcastSeriePage = ({ data, pageContext }) => {
  const serie = data.prismicPodcastserie.data
  const episodes = data.allPrismicPodcastepisode.edges
  const {
    subhead,
    episodes_heading,
    sidebar_heading,
    more_details,
    social_image,
  } = data.prismicPodcastpage.data
  episodes.sort((a, b) => a.node.data.episode - b.node.data.episode)

  return (
    <LayoutRefactored locale={pageContext.lang}>
      <SEO
        title={serie.title.text}
        description={serie.title.text}
        image={social_image.url}
      />
      <CaseStudyHeader />
      <S.Hero bg={serie.serie_color ? serie.serie_color : Colors.navy}>
        <Width>
          <S.Subhead color={Colors.white}>
            <LocalizedLink to="/podcast">{subhead?.text ?? ''}</LocalizedLink>{' '}
            &gt; {serie.title.text}
          </S.Subhead>
          <S.Title color={Colors.white}>{serie.title.text}</S.Title>
          <S.Text color={Colors.white}>{serie.summary.text}</S.Text>
        </Width>
      </S.Hero>
      <DiagonalBox bg="white">
        <S.ContentWrapper>
          <S.ContentMain>
            {episodes_heading?.text && (
              <S.EpisodesTitle>{episodes_heading.text}</S.EpisodesTitle>
            )}

            {episodes.map((ep, i) => {
              const {
                episode,
                duration,
                summary,
                title,
                disable_link,
                meta_release_date,
              } = ep.node.data

              const { uid } = ep.node

              return (
                <S.EpisodePreview key={`series-epi-prev-${i}`} lang="en">
                  <S.EpisodeInfo>
                    {serie.title.text && serie.title.text}{' '}
                    {episode && `| ep. ${episode}`}{' '}
                    {duration && `| ${duration}`}
                  </S.EpisodeInfo>
                  {title.text &&
                    (disable_link === true ? (
                      <>
                        <S.EpisodeTitle>{title.text}</S.EpisodeTitle>
                        {meta_release_date.text && (
                          <S.EpisodeReleaseDate>
                            {meta_release_date.text}
                          </S.EpisodeReleaseDate>
                        )}
                      </>
                    ) : (
                      <LocalizedLink
                        to={`/podcast/series/${data.prismicPodcastserie.uid}/${uid}`}
                        hover={Colors.red}
                      >
                        <S.EpisodeTitle>{title.text}</S.EpisodeTitle>
                      </LocalizedLink>
                    ))}
                  {summary.html && (
                    <S.EpisodeExcerpt
                      dangerouslySetInnerHTML={{ __html: summary.html }}
                    />
                  )}
                  {!disable_link && (
                    <S.DetailsLink
                      to={`/podcast/series/${data.prismicPodcastserie.uid}/${uid}`}
                    >
                      {more_details.text}
                    </S.DetailsLink>
                  )}
                </S.EpisodePreview>
              )
            })}
          </S.ContentMain>
          <S.ContentSidebar>
            <S.SerieImg fluid={serie.image.fluid} alt={serie.title.text} />
            {sidebar_heading?.text && (
              <S.Subhead>{sidebar_heading.text}</S.Subhead>
            )}
            <PodcastSourcesList />
          </S.ContentSidebar>
        </S.ContentWrapper>
      </DiagonalBox>
    </LayoutRefactored>
  )
}
export default PodcastSeriePage
