import React from 'react'
import styled from 'styled-components'
import { JobDepartment, Width } from '@/components'
import { Breakpoints, Colors, Type } from '@/styles'
import LocalizedLink from '../LocalizedLink/LocalizedLink'

export const JobListWrapper = styled(Width)`
  margin-top: 120px;
  margin-bottom: 80px;
  @media (min-width: ${Breakpoints.tablet}) {
    margin-top: 150px;
    margin-bottom: 0px;
  }
  @media (min-width: ${Breakpoints.desktop}) {
    margin-top: 200px;
  }
`

export const SectionTitle = styled.h3`
  ${Type.header3};
  margin-bottom: 29px;
`

export const SectionDescription = styled.div`
  ${Type.body30};
`

export const StyledJobDepartment = styled(JobDepartment)`
  margin-bottom: 75px;
`

export const TableCell = styled.div`
  ${Type.body40};
  padding: 12px;
`

export const HighlightedTableCell = styled(TableCell)`
  font-weight: 700;
`

export const StyledList = styled.div`
  width: 100%;
  margin-top: 67px;

  a:nth-child(odd) {
    background-color: ${Colors.lightGray};
  }
`

export const StyledLink = styled(props => <LocalizedLink {...props} />)`
  display: grid;
  grid-template-areas: 'company department' 'role role';
  grid-template-columns: 1fr 1fr;

  & > div:last-of-type {
    padding-top: 0px;
  }
  & > div:nth-child(-n + 2) {
    padding-bottom: 0px;
  }

  &:hover {
    ${HighlightedTableCell} {
      color: ${Colors.red};
    }
  }

  @media (min-width: ${Breakpoints.tablet}) {
    grid-template-areas: 'company department role';
    grid-template-columns: 25% 25% 50%;

    & > div:last-of-type {
      padding-top: 12px;
    }
    & > div:nth-child(-n + 2) {
      padding-bottom: 12px;
    }
  }
`
