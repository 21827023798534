import * as React from 'react'

function Overcast(props) {
  return (
    <svg width={66} height={67} viewBox="0 0 66 67" fill="none" {...props}>
      <path
        d="M0 33.332c0-18.246 14.754-33 33-33 18.246 0 33 14.754 33 33 0 18.246-14.754 33-33 33-18.246 0-33-14.754-33-33z"
        fill="#F2F2F2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.12 55.733a22.227 22.227 0 01-22.281-22.281 22.227 22.227 0 0122.28-22.281 22.227 22.227 0 0122.282 22.28A22.227 22.227 0 0133.12 55.734zm0-8.82l1.671-1.67-1.671-6.406-1.671 6.405 1.67 1.672zm-2.135-.092l-.836 3.156 1.95-1.95-1.114-1.206zm4.27 0l-1.114 1.114 1.95 1.95-.836-3.064zm1.579 5.755l-3.714-3.713-3.714 3.713c1.207.186 2.414.372 3.714.372 1.3 0 2.507-.093 3.714-.372zm-3.714-38.62c-10.77 0-19.496 8.727-19.496 19.496 0 8.54 5.477 15.782 13.09 18.382l4.178-15.504c-.929-.65-1.486-1.764-1.486-2.971a3.724 3.724 0 013.714-3.713 3.724 3.724 0 013.714 3.713c0 1.207-.557 2.228-1.486 2.971l4.178 15.504a19.401 19.401 0 0013.09-18.382c0-10.77-8.727-19.496-19.496-19.496zm12.719 29.15c-.465.558-1.3.744-1.95.28-.65-.465-.743-1.3-.279-1.857 0 0 2.507-3.342 2.507-8.077 0-4.735-2.507-8.077-2.507-8.077-.464-.557-.37-1.392.279-1.857.65-.464 1.485-.278 1.95.279.186.186 3.063 3.992 3.063 9.655 0 5.663-2.877 9.47-3.063 9.655zm-6.777-3.62c-.557-.557-.465-1.578.092-2.135 0 0 1.393-1.486 1.393-3.9 0-2.413-1.393-3.805-1.393-3.898-.557-.557-.557-1.486-.092-2.135.557-.65 1.392-.65 1.95-.093.092.093 2.32 2.413 2.32 6.127s-2.228 6.034-2.32 6.127c-.558.557-1.393.464-1.95-.093zm-11.884 0c-.557.557-1.393.65-1.95.093-.092-.185-2.32-2.413-2.32-6.127s2.228-5.942 2.32-6.127c.557-.557 1.486-.557 1.95.093.557.557.465 1.578-.092 2.135 0 .093-1.393 1.485-1.393 3.899 0 2.413 1.393 3.9 1.393 3.9.557.556.65 1.485.092 2.134zm-4.827 3.9c-.65.464-1.486.278-1.95-.28-.186-.185-3.063-3.99-3.063-9.654 0-5.663 2.877-9.47 3.063-9.655.464-.557 1.3-.743 1.95-.279.65.465.742 1.3.278 1.857 0 0-2.506 3.342-2.506 8.077 0 4.735 2.506 8.077 2.506 8.077.464.557.279 1.392-.278 1.856z"
        fill="#FC7E0F"
      />
    </svg>
  )
}

export default Overcast
