import styled from 'styled-components'
import Img from 'gatsby-image'
import { Type, Colors, Breakpoints } from '@/styles'

export const SeriesImg = styled(Img)`
  filter: drop-shadow(0px 10px 10px rgba(0, 0, 0, 0.2));
  margin: 60px 0;
  max-width: 220px;
  @media (min-width: ${Breakpoints.desktop}) {
    margin-top: 0;
  }
`

export const SeriesTitle = styled.h3`
  ${Type.body20};
  font-weight: ${Type.fontWeights.bold};
  margin-bottom: 12px;
  color: ${Colors.navy};
  @media (min-width: ${Breakpoints.desktop}) {
    font-size: 20px;
    line-height: 26px;
  }
`

export const SeriesDescription = styled.p`
  ${Type.body40};
  margin-bottom: 50px;
  color: ${Colors.navy};
`

export const SeriesSubhead = styled.p`
  ${Type.subhead20};
  color: ${({ color }) => (color ? color : Colors.text)};
  margin-bottom: 10px;
`

export const SeriesEpisodes = styled.ol`
  margin-top: 12px;
  padding: 0 0 0 20px;
`

export const SeriesEpisode = styled.li`
  a {
    text-decoration: underline;
    transition: color 0.2s ease-in-out;
    &:hover {
      color: ${Colors.red};
    }
  }
  :not(:last-of-type) {
    margin-bottom: 12px;
  }
`

export const SeriesAllEpisodes = styled.p`
  margin-top: 40px;
  a {
    text-decoration: underline;
    transition: color 0.2s ease-in-out;
    &:hover {
      color: ${Colors.red};
    }
  }
`
