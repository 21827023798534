import React from 'react'
import styled from 'styled-components'
import { Type, Colors, Sizes, Breakpoints } from '@/styles'
import HoverArrow from '../HoverArrow/HoverArrow'
import LocalizedLink from '../LocalizedLink/LocalizedLink'

export const BannerComponent = styled.div`
  background-color: ${Colors.navy};
  text-align: center;
  position: relative;
  top: ${Sizes.navHeight};
  padding: 0 20px;

  @media (min-width: ${Breakpoints.tablet}) {
    padding: 0;
  }
`

export const StyledArrow = styled(HoverArrow)`
  display: inline-block;
  height: 12px;
  width: 40px;
  white-space: nowrap;
  transform: scale(0.4);
  margin-left: -8px;
  padding-top: 3px;
  path {
    fill: ${props => props.color};
  }

  .arrow {
    left: -25px;
  }
`

export const StyledLink = styled(props => <LocalizedLink {...props} />)`
  ${Type.body30};
  color: ${Colors.white};
  display: inline-block;
  padding: 20px 0;
  &:hover {
    color: ${Colors.white};

    ${StyledArrow} {
      .arrow {
        left: 0px;
      }
    }
  }
`
