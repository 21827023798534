import React from 'react'

const LinkedIn = props => {
  return (
    <svg
      width="52"
      height="52"
      viewBox="0 0 52 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-label="LinkedIn"
      {...props}
    >
      <title>LinkedIn</title>
      <g clipPath="url(#clipLinkedIn)" fill="#fff">
        <path d="M25.58 51A25.48 25.48 0 1125.57.04 25.48 25.48 0 0125.58 51zm0-48.21a22.74 22.74 0 10-.01 45.48 22.74 22.74 0 00.01-45.48z" />
        <path d="M39.98 27.47v9.5a.42.42 0 01-.42.44h-4.92a.42.42 0 01-.42-.43V28.1c0-2.33-.83-3.92-2.91-3.92a3.14 3.14 0 00-2.96 2.12c-.16.46-.22.94-.2 1.43v9.25a.42.42 0 01-.42.43h-4.9a.42.42 0 01-.44-.43c0-2.36.07-13.79 0-16.47a.42.42 0 01.43-.43h4.9a.42.42 0 01.42.42v2.05-.06a5.72 5.72 0 015.21-2.8c3.74 0 6.63 2.47 6.63 7.8v-.02zm-26.1 9.98h4.92a.42.42 0 00.42-.43V20.5a.42.42 0 00-.42-.42h-4.93a.43.43 0 00-.43.42v16.48a.43.43 0 00.43.47zM16.16 17.87a3.09 3.09 0 100-6.17 3.09 3.09 0 000 6.17z" />
      </g>
      <defs>
        <clipPath>
          <path fill="#fff" d="M.1.05h50.96v50.96H.1z" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default LinkedIn
