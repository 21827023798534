import React from 'react'
import styled from 'styled-components'
import { animated } from 'react-spring'

const Plus = ({ title, ...props }) => {
  return (
    <Asset viewBox="0 0 20 20" {...props} role="img">
      <title>{title}</title>
      <path
        stroke="#313C49"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M16.364 3.636L3.636 16.364m0-12.728l12.728 12.728"
      />
    </Asset>
  )
}

export default Plus

const Asset = styled(animated.svg)`
  width: 20px;
  height: 20px;
  transform: ${props => props.rotate};
  will-change: transform;
`
