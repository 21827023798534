import React from 'react'
import PropTypes, { string } from 'prop-types'

import * as S from './styles'

const HoverArrow = props => {
  const { arrowProps, ...rest } = props

  return (
    <S.HoverArrowComponent {...rest}>
      <S.LeftEndMask>
        <S.StyledLeftEnd role="none" />
      </S.LeftEndMask>
      <S.Mask>
        <S.StyledArrow {...arrowProps} className="arrow" />
      </S.Mask>
    </S.HoverArrowComponent>
  )
}

HoverArrow.propTypes = {
  arrowProps: PropTypes.shape({
    title: string,
    'aria-label': string,
    role: string,
  }),
}

export default HoverArrow
