import React from 'react'

const Twitter = props => {
  return (
    <svg
      width="52"
      height="52"
      fill="none"
      viewBox="0 0 52 52"
      role="img"
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      aria-label="Twitter"
    >
      <title>Twitter</title>
      <g clipPath="url(#clipTwitter)" fill="#fff">
        <path d="M25.57 51.05a25.53 25.53 0 110-51.06 25.53 25.53 0 010 51.06zm0-48.31a22.78 22.78 0 100 45.56 22.78 22.78 0 000-45.56z" />
        <path d="M40.26 16.2c-.96.42-1.97.71-3 .87.48-.09 1.2-.94 1.48-1.31.45-.56.79-1.2 1-1.87v-.16a.19.19 0 00-.16 0c-1.13.6-2.32 1.07-3.57 1.37a.26.26 0 01-.25-.06l-.3-.33a6.54 6.54 0 00-4.4-1.43 6.55 6.55 0 00-4.55 2.37c-.58.71-.99 1.54-1.21 2.43a6.55 6.55 0 00-.07 2.56c0 .15 0 .17-.12.15-4.9-.73-8.97-2.49-12.27-6.25-.14-.16-.21-.16-.34 0-1.44 2.16-.73 5.61 1.06 7.34.24.24.5.46.76.67a6.12 6.12 0 01-2.37-.67c-.14-.08-.21 0-.22.13a3 3 0 000 .7 6.3 6.3 0 004.66 5.26c-.76.16-1.54.19-2.31.08-.17 0-.24.06-.17.22a6.55 6.55 0 004.88 4.09c.21 0 .44 0 .66.09h-.04c-.55.83-2.44 1.46-3.33 1.77-1.62.57-3.35.8-5.06.64h-.4c-.08 0 0 .11.07.18a17.07 17.07 0 004.44 1.98c6.08 1.68 12.93.44 17.49-4.08 3.6-3.53 4.83-8.44 4.83-13.36 0-.18.23-.3.37-.4.9-.7 1.7-1.54 2.37-2.47.1-.14.15-.3.14-.48.02-.1.03-.08-.07-.02z" />
      </g>
      <defs>
        <clipPath>
          <path fill="#fff" d="M.05 0H51.1v51.05H.05z" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default Twitter
