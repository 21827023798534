import React from 'react'
import ReactPlayer from 'react-player/lazy'
import * as S from './styles'

const ResponsiveVideo = ({
  url = 'https://www.youtube.com/watch?v=FwwIYdB_wic',
  ...restProps
}) => {
  /** This will render mux videos on a silent loop without controls */
  const isMuxVideo =
    typeof url === 'string' && url.startsWith('https://stream.mux.com/')
  const muxProps = isMuxVideo
    ? { playing: true, loop: true, playsinline: true, muted: true }
    : {}

  return (
    <S.PlayerComponent>
      <ReactPlayer
        url={url}
        className="video-player"
        width="100%"
        height="100%"
        light={isMuxVideo ? false : !restProps.playing}
        {...muxProps}
        {...restProps}
      />
    </S.PlayerComponent>
  )
}

export default ResponsiveVideo
