import React from 'react'
import styled from 'styled-components'
import { LocalizedLink } from '@/components'
import { Breakpoints, Sizes, Type, Colors } from '@/styles'

export const PodcastPageComponent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`

export const Hero = styled.div`
  padding-top: calc(${Sizes.navHeight} + 130px);
  @media (min-width: ${Breakpoints.tablet}) {
    padding-top: calc(${Sizes.navHeight} + 197px);
  }

  > div {
    padding-bottom: 70px;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    bottom: 0;
    right: 0;
    background: ${({ bg }) => (bg ? bg : Colors.gray)};
  }
`

/* --------------------
Typography
-------------------- */

export const Title = styled.h1`
  ${Type.header2};
  color: ${({ color }) => (color ? color : Colors.text)};
  margin-bottom: 10px;
  @media (min-width: ${Breakpoints.desktop}) {
    margin-bottom: 35px;
    max-width: 674px;
  }
`

export const TitleH4 = styled(Title)`
  ${Type.header4};
  margin-bottom: 38px;
`

export const Subhead = styled.p`
  ${Type.subhead20};
  color: ${({ color }) => (color ? color : Colors.text)};
  margin-bottom: 10px;
  @media (min-width: ${Breakpoints.desktop}) {
    margin-bottom: 10px;
  }
`

export const Text = styled.p`
  ${Type.body20};
  color: ${({ color }) => (color ? color : Colors.text)};
  margin-bottom: 10px;
  @media (min-width: ${Breakpoints.desktop}) {
    margin-bottom: 10px;
    max-width: 674px;
  }
`

/* --------------------
Content
-------------------- */

export const ContentWrapper = styled.div`
  padding-top: 50px;
  padding-bottom: 50px;
  width: 100%;
  @media (min-width: ${Breakpoints.tablet}) {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-gap: 20px;
  }
  @media (min-width: ${Breakpoints.desktop}) {
    padding-top: 120px;
    padding-bottom: 120px;
  }
`

export const ContentMain = styled.div`
  @media (min-width: ${Breakpoints.tablet}) {
    grid-column: 1 / span 7;
    padding-top: 12px;
    align-self: start;
  }
`

export const ContentSidebar = styled.div`
  @media (min-width: ${Breakpoints.tablet}) {
    grid-column: 10 / span 3;
    align-self: start;
    margin-top: 20px;
  }
`

/* --------------------
Series 
-------------------- */

export const SeriesList = styled.ul`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 25px;
  position: relative;
  z-index: 2;
  margin: 20px 0 -120px;
  padding: 0;

  @media (min-width: ${Breakpoints.tablet}) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (min-width: ${Breakpoints.desktop}) {
    grid-template-columns: repeat(6, 1fr);
  }

  li {
    list-style: none;
    outline: 2px solid ${Colors.white};
    transition: outline 0.2s ease-in-out;

    :hover {
      outline-width: 5px;
    }
  }
`

/* --------------------
Episode
-------------------- */

export const EpisodePreview = styled.div`
  margin-bottom: 60px;
  @media (min-width: ${Breakpoints.tablet}) {
    grid-column: 10 / span 3;
    padding-top: 12px;
    align-self: start;
    :last-of-type {
      margin-bottom: 0;
    }
  }
`

export const EpisodeInfo = styled.p`
  ${Type.subhead20};
  font-weight: ${Type.fontWeights.light};
  color: ${Colors.navy};
`

export const EpisodeTitle = styled.h2`
  ${Type.header4};
  margin: 12px 0 12px;
  color: ${Colors.navy};
  transition: color 0.2s ease-in-out;
  &:hover {
    color: ${Colors.red};
  }
`

export const EpisodeReleaseDate = styled.p`
  ${Type.body40};
  font-style: italic;
  color: ${Colors.navy};
  margin-bottom: 6px;
`

export const EpisodeExcerpt = styled.div`
  ${Type.body40};
  color: ${Colors.navy};
  > p {
    margin-bottom: 12px;
  }
`

export const DetailsLink = styled(props => <LocalizedLink {...props} />)`
  color: ${Colors.navy};
  text-decoration: underline;
  &:hover {
    color: ${Colors.red};
  }
`
