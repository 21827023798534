import * as React from 'react'

function ApplePodcast (props) {
  return (
    <svg width={66} height={66} viewBox='0 0 66 66' fill='none' {...props}>
      <path
        d='M0 33C0 14.754 14.754 0 33 0c18.246 0 33 14.754 33 33 0 18.246-14.754 33-33 33C14.754 66 0 51.246 0 33z'
        fill='#F2F2F2'
      />
      <path
        d='M31.866 57.325c-1.758-.628-2.135-1.483-2.856-6.49-.838-5.819-1.021-9.421-.536-10.562.644-1.513 2.392-2.371 4.838-2.38 2.425-.01 4.19.855 4.837 2.38.487 1.139.305 4.743-.534 10.563-.57 4.066-.884 5.093-1.67 5.784-1.08.957-2.612 1.222-4.065.707l-.014-.002zm-7.509-5.97c-6.07-2.986-9.958-8.038-11.372-14.76-.354-1.734-.413-5.872-.079-7.463.884-4.272 2.573-7.613 5.382-10.567 4.046-4.266 9.251-6.521 15.045-6.521 5.736 0 10.92 2.213 14.869 6.352 3.005 3.123 4.694 6.429 5.558 10.783.295 1.45.295 5.402.02 7.032a20.881 20.881 0 01-7.955 13.002c-1.493 1.128-5.146 3.096-5.735 3.096-.216 0-.236-.224-.138-1.13.177-1.453.354-1.755 1.179-2.101 1.316-.55 3.555-2.145 4.93-3.524a18.768 18.768 0 004.93-8.681c.51-2.023.452-6.521-.118-8.603-1.787-6.62-7.189-11.765-13.808-13.14-1.925-.393-5.42-.393-7.365 0-6.698 1.374-12.237 6.776-13.926 13.572-.452 1.846-.452 6.344 0 8.19 1.12 4.498 4.026 8.623 7.837 11.078.746.491 1.65 1.002 2.023 1.159.825.354 1.002.648 1.159 2.102.098.883.078 1.139-.138 1.139-.137 0-1.14-.432-2.2-.943l-.098-.073zm.079-7.99c-2.043-1.631-3.85-4.522-4.596-7.358-.452-1.713-.452-4.97.02-6.679 1.237-4.611 4.635-8.186 9.349-9.866 1.61-.57 5.185-.697 7.169-.259 6.835 1.523 11.686 8.367 10.743 15.158-.373 2.736-1.316 4.983-2.985 7.07-.825 1.055-2.828 2.825-3.182 2.825-.059 0-.118-.668-.118-1.481V41.29l1.022-1.218c3.85-4.611 3.574-11.054-.629-15.28-1.63-1.647-3.516-2.613-5.951-3.059-1.572-.29-1.905-.29-3.555-.02-2.505.409-4.445 1.38-6.168 3.084-4.223 4.184-4.498 10.66-.648 15.275l1.014 1.218v1.493c0 .825-.065 1.492-.146 1.492-.078 0-.648-.392-1.257-.883l-.082-.028zm6.815-8.02c-1.748-.814-2.69-2.348-2.71-4.347 0-1.797 1.001-3.365 2.73-4.282 1.1-.577 3.044-.577 4.144.004 1.198.623 2.18 1.83 2.554 3.092 1.139 3.873-2.966 7.267-6.678 5.533h-.04z'
        fill='#CF6BF5'
      />
      <path
        d='M33.645 36.107a4.898 4.898 0 100-9.797 4.898 4.898 0 000 9.797z'
        fill='#CF6BF5'
      />
    </svg>
  )
}

export default ApplePodcast
