import styled from 'styled-components'

export const PodcastPlayerComponent = styled.div`
  position: relative;
  padding-top: calc(
    138 / 638 * 100%
  ); /* height / width * 100% = calculate aspect ratio */
  .react-player {
    position: absolute;
    top: 0;
    left: 0;
  }
`
