import React from 'react'
import styled from 'styled-components'

import { LocalizedLink } from '@/components'

export const NotFoundComponent = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 100px;
`

export const StyledLink = styled(props => <LocalizedLink {...props} />)`
  text-decoration: underline;
  margin-top: 30px;
  display: block;
`
