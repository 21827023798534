import React, { useEffect } from 'react'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'

import {
  Footer,
  NextCaseStudy,
  CaseStudyHeader,
  SEO,
  GlobalStyle,
  Cookies,
  ResponsiveVideo,
} from '@/components'
import useStore from '@/store/'
import fontColorMap from '@/data/fontColorMap'
// eslint-disable-next-line no-unused-vars
import { AllCaseStudies } from '@/graphql/AllCaseStudies'
import * as S from './styles'
import locales from '../../../config/i18n'

const CaseStudyLayout = props => {
  const locale = props.pageContext.lang
  const language = useStore(state => state.locale)
  const switchLocale = useStore(state => state.switchLocale)

  useEffect(() => {
    if (locale && locale !== language) {
      switchLocale(locale)
    }
  }, [locale, language, switchLocale])

  const slug = props.pageContext.uid

  const projectListData = props.data.prismicProjectList.data
  const caseStudies = projectListData.project_list
  const currentCaseStudy = caseStudies.find(item => {
    return item.uid === slug
  })

  const currentIndex = caseStudies.findIndex(item => item.uid === slug)
  const nextCaseStudy = caseStudies[currentIndex + 1] || caseStudies[0]

  const {
    hero_image,
    primary_color,
    text_color,
    title,
    top_description,
    tags,
    secondary_description,
    external_url,
    hero_video,
    hero_video_width,
    hero_video_height,
  } = currentCaseStudy

  const foregroundColor = fontColorMap[text_color]

  const { social_image } = props.data.prismicMeta.data

  return (
    <S.CaseStudyLayoutComponent>
      <Helmet htmlAttributes={{ lang: locales[locale].path || 'en' }} />
      <SEO
        title={title.text}
        description={top_description.text}
        image={social_image.url}
      />
      <GlobalStyle />
      <CaseStudyHeader />
      <S.Header color={primary_color}>
        <S.BackWrap>
          <S.BackMaxWidth>
            <S.BackLink
              to="/"
              aria-label={projectListData.back_button_label.text}
            >
              <S.BackButton color={foregroundColor} role="none" />
            </S.BackLink>
          </S.BackMaxWidth>
        </S.BackWrap>
        <S.MaxWidth>
          <S.TitleAndTags>
            {title.text && (
              <S.Title color={foregroundColor}>{title.text}</S.Title>
            )}
            {tags.text && <S.Tags color={foregroundColor}>{tags.text}</S.Tags>}
          </S.TitleAndTags>
          {top_description.text && (
            <S.LongDescription header color={foregroundColor}>
              {top_description.text}
            </S.LongDescription>
          )}
        </S.MaxWidth>
      </S.Header>
      <S.StyledWidth>
        <S.Media>
          {hero_video?.url ? (
            <S.ResponsiveVideoContainer
              height={hero_video_height}
              width={hero_video_width}
            >
              <ResponsiveVideo
                url={hero_video.url}
                playing={true}
                loop={true}
                playsinline={true}
                muted={true}
              />
            </S.ResponsiveVideoContainer>
          ) : (
            hero_image?.localFile?.childImageSharp?.fluid && (
              <S.StyledImage
                fluid={hero_image.localFile.childImageSharp.fluid}
                alt={hero_image.alt || ''}
              />
            )
          )}
        </S.Media>
      </S.StyledWidth>
      <S.SecondaryDescriptionMaxWidth>
        {secondary_description.text && (
          <S.LongDescription>{secondary_description.text}</S.LongDescription>
        )}
        {external_url.url && (
          <S.Url
            as="a"
            color={primary_color}
            href={external_url.url}
            target="_blank"
            rel="noopener noreferrer"
          >
            {projectListData.external_link_text.text}{' '}
            <Arrow color={primary_color} role="none" />
          </S.Url>
        )}
      </S.SecondaryDescriptionMaxWidth>
      <NextCaseStudy url={`/case-studies/${nextCaseStudy.uid}`}>
        {nextCaseStudy.title.text}
      </NextCaseStudy>
      <Footer />
      <Cookies />
    </S.CaseStudyLayoutComponent>
  )
}

export const pageQuery = graphql`
  query CaseStudies($lang: String!) {
    prismicProjectList(lang: { eq: $lang }) {
      ...AllCaseStudies
      data {
        external_link_text {
          text
        }
        back_button_label {
          text
        }
      }
    }
    prismicMeta {
      data {
        social_image {
          url
        }
      }
    }
  }
`

export default CaseStudyLayout

const Arrow = props => (
  <S.Arrow width={35} height={30} viewBox="0 0 35 30" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.686.858l12.728 12.728a2 2 0 010 2.828L21.686 29.142a2 2 0 01-2.828-2.828L28.172 17H2a2 2 0 110-4h26.172l-9.314-9.314A2 2 0 1121.686.858z"
    />
  </S.Arrow>
)
