import styled from 'styled-components'

export const PodcastSourcesHeaderComponent = styled.div`
  margin-top: 20px;
`

export const List = styled.ul`
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
`

export const ListItem = styled.li`
  cursor: pointer;
  transition: opacity 0.2s ease-in-out;
  svg {
    height: 27px;
    width: 27px;
  }
  :not(:last-of-type) {
    margin-right: 16px;
  }
  &:hover {
    opacity: 0.5;
  }
`
